import React, { useEffect, useState, useRef, Component } from 'react'
import useAxios from '../utils/useAxios';
import ValidateForm from "../components/validation.js"
import ValidateFormOptimisation from "../components/validationforoptimisation.js"
import ValidatePortfolioForm from "../components/validation_portfolio.js"
import ValidateAssetDeduction from "../components/validation_assetdeduction.js"
import ValidateStatePensionForm from "../components/validation_statepension.js"
import ValidateOccupationalPensionForm from "../components/validation_occupationalpension.js"
import ValidateContributionForm from "../components/validation_contribution.js"
import ValidateExtraContributionForm from "../components/validation_extracontribution.js"
import ValidateAnnuityForm from "../components/validation_annuity.js"
import ValidateIncomeForm from "../components/validation_income.js"
import ValidateFlexIncomeForm from "../components/validation_flexincome.js"
import MaxMinPlotChart from "../components/maxminchart.js"
// import DistChart from "../components/distchart.js"
// import DistValueFanChart from "../components/portfoliovaluefanchart.js"
import MasterLineChart from "../components/masterlinechart.js"
// import DrawDistChart from "../components/drawdistchart.js"
// import DrawFanChart from "../components/drawdistfanchart.js"
import DistTypeChart from "../components/drawbytypedistchart.js"
import DrawHistChart from "../components/drawhistchart.js"
// import BestSWRChart from "../components/bestswrchart.js"
// import SafeFundingChart from "../components/safefundingchart.js"
// import SafeFundingLevelChart from "../components/safefundinglevelchart.js"
import validatesanserrorsForm from "../components/validationsanserrors.js"
import PortfolioBreakdownPopup from "../components/portfoliobreakdownpopup.js"
import ButtonExample from "../components/loadingbutton.js";
import LogoBanner from "../components/logobanner.png"
import TradeOffAnalysis from "../components/tradeoffanalysis.js"
import PlanButtons from "../components/planbuttons.js";
import FormTemplate from "../components/formtemplate.js";
import FormTemplateLight from "../components/formtemplatelight.js";
import FormTemplateInteger from "../components/formtemplateinteger.js";
import ReLoginForm from '../views/relogin';
import { usePresetStore } from "../components/datastore.js";
import { useAuthStore } from '../store/auth'
// import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import '../styles/mystyles.css'
import '../App.css' // Import the CSS file
import { FaInfoCircle } from 'react-icons/fa'; // Font Awesome info icon
import { useLocation } from 'react-router-dom';


function CalcDash1() {
    const tempvar = false

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    useEffect(() => {
      const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
      };
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
    }, []);

    const webpageData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Retire Smart Calc",
      "logo": "https://www.retiresmartcalc.com/logo.png",
      "description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
    };
    

    const api = useAxios();  // Ensure this is at the top level of your component or function
    const location = useLocation();
    const today = new Date().toISOString().split('T')[0];
    const presetObject = usePresetStore((state) => state.presetObject);
    const setPresetObject = usePresetStore((state) => state.setPresetObject);
    const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
    const planId = usePresetStore((state) => state.planId);
    const setPlanId = usePresetStore((state) => state.setPlanId);
    const resetPlanId = usePresetStore((state) => state.resetPlanId);
    const logInState = useAuthStore().isLoggedIn();
    const userId = useAuthStore().user();
    const [yearList, setYearList] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [optrun, setOptrun] = useState(false);
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState(0.0);
    const [decresult, setDecresult] = useState([0,0,0,0,0,0,0,0,0,0,0]);
    const [allresult, setAllresult] = useState([]);
    const [allresultGA, setAllresultGA] = useState([]);
    const [allresultTE, setAllresultTE] = useState([]);
    const [allresultTD, setAllresultTD] = useState([]);
    const [portfoliovaluepercentiles, setPortfoliovaluepercentiles] = useState([]);
    const [incomepercentiles, setIncomepercentiles] = useState([]);
    const [alldrawstreams, setAlldrawstreams] = useState([]);
    const [drawstreamsbytype, setDrawstreamsbytype] = useState([]);
    const [averagedraw, setAveragedraw] = useState(0.0);
    const [averageadjusteddraw, setAverageadjusteddraw] = useState(0.0);
    const [bestswrs, setBestswrs] = useState([]);
    const [safefunding, setSafefunding] = useState([]);
    const [safefundinglevel, setSafefundinglevel] = useState([]);
    const [resultyears, setResultyears] = useState([]);
    const [yearsForChart, setYearsForChart] = useState([]);
    const [yearsForChartShort, setYearsForChartShort] = useState([]);
    const [drawhistdata, setDrawhistdata] = useState({
      histogram: [],
      bins: [],
    });
    const [portfolioTotal,  setPortfolioTotal] = useState();
    const [sumdrawadjustedavg, setSumdrawadjustedavg] = useState(0.0)
    const [safeFundingPercentiles, setSafeFundingPercentiles] = useState([]);
    const [safeWithdrawalPercentiles, setSafeWithdrawalPercentiles] = useState([]);

    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [buttonlabel, setButtonlabel] = useState('Calculating...')
    const [switchvalue, setSwitchvalue] = useState(1)
    const handleSwitchChange = (val) => setSwitchvalue(val);
    const [switchvalue2, setSwitchvalue2] = useState(2)
    const handleSwitchChange2 = (val) => setSwitchvalue2(val);
    const [switchvalue3, setSwitchvalue3] = useState(1)
    const handleSwitchChange3 = (val) => setSwitchvalue3(val);    
    const [switchvalue4, setSwitchvalue4] = useState(2)
    const handleSwitchChange4 = (val) => setSwitchvalue4(val); 

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {setIsModalOpen(true)};
    const closeModal = () => {setIsModalOpen(false)};

    const [isReLoginModalOpen, setIsReLoginModalOpen] = useState(false);
    const openReLoginModal = () => setIsReLoginModalOpen(true);
    const closeReLoginModal = () => setIsReLoginModalOpen(false);

    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
    const openAssetModal = () => {setIsAssetModalOpen(true)};
    const closeAssetModal = () => {setIsAssetModalOpen(false)};

    const [isStateModalOpen, setIsStateModalOpen] = useState(false);
    const openStateModal = () => {setIsStateModalOpen(true)};
    const closeStateModal = () => {setIsStateModalOpen(false)};

    const [isOccupationalModalOpen, setIsOccupationalModalOpen] = useState(false);
    const openOccupationalModal = () => {setIsOccupationalModalOpen(true)};
    const closeOccupationalModal = () => {setIsOccupationalModalOpen(false)};   

    const [isContributionModalOpen, setIsContributionModalOpen] = useState(false);
    const openContributionModal = () => {setIsContributionModalOpen(true)};
    const closeContributionModal = () => {setIsContributionModalOpen(false)}; 

    const [isExtraordinaryModalOpen, setIsExtraordinaryModalOpen] = useState(false);
    const openExtraordinaryModal = () => {setIsExtraordinaryModalOpen(true)};
    const closeExtraordinaryModal = () => {setIsExtraordinaryModalOpen(false)};  

    const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
    const openIncomeModal = () => {setIsIncomeModalOpen(true)};
    const closeIncomeModal = () => {setIsIncomeModalOpen(false)};  

    const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
    const openBonusModal = () => {setIsBonusModalOpen(true)};
    const closeBonusModal = () => {setIsBonusModalOpen(false)};  
    
    const [isAnnuityModalOpen, setIsAnnuityModalOpen] = useState(false);
    const openAnnuityModal = () => {setIsAnnuityModalOpen(true)};
    const closeAnnuityModal = () => {setIsAnnuityModalOpen(false)};  

    const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
    const openPortfolioModal = () => {setIsPortfolioModalOpen(true)};
    const closePortfolioModal = () => {setIsPortfolioModalOpen(false)};

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [modalResetMessage, setModalResetMessage] = useState('Are you sure you want to reset the data? This action cannot be undone.');
    const openResetModal = () => {setIsResetModalOpen(true)};
    const closeResetModal = () => {setIsResetModalOpen(false)};
    
    const [modalmessage, setModalmessage] = useState();

    const [inPortfolioEquity, setInPortfolioEquity] = useState({});
    const [inPortfolioBonds, setInPortfolioBonds] = useState({});
    const [inPortfolioIndex, setInPortfolioIndex] = useState({});
    const [inPortfolioTotal, setInPortfolioTotal] = useState({});

    const [plans, setPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState(null); // State for selected plan ID for deletion

    const [isRetrieveModalOpen, setIsRetrieveModalOpen] = useState(false);
    const openRetrieveModal = () => {setIsRetrieveModalOpen(true)};
    const closeRetrieveModal = () => {setIsRetrieveModalOpen(false)};

    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal visibility

    const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
    const openAddAssetModal = () => setIsAddAssetModalOpen(true);
    const closeAddAssetModal = () => setIsAddAssetModalOpen(false);

    const [isAddStateModalOpen, setIsAddStateModalOpen] = useState(false);
    const openAddStateModal = () => setIsAddStateModalOpen(true);
    const closeAddStateModal = () => setIsAddStateModalOpen(false);

    const [isAddOccupationModalOpen, setIsAddOccupationModalOpen] = useState(false);
    const openAddOccupationModal = () => setIsAddOccupationModalOpen(true);
    const closeAddOccupationModal = () => setIsAddOccupationModalOpen(false);

    const [isAddContributionModalOpen, setIsAddContributionModalOpen] = useState(false);
    const openAddContributionModal = () => setIsAddContributionModalOpen(true);
    const closeAddContributionModal = () => setIsAddContributionModalOpen(false);
    
    const [isAddExtraordinaryModalOpen, setIsAddExtraordinaryModalOpen] = useState(false);
    const openAddExtraordinaryModal = () => setIsAddExtraordinaryModalOpen(true);
    const closeAddExtraordinaryModal = () => setIsAddExtraordinaryModalOpen(false);

    const [isAddIncomeRequirementsModalOpen, setIsAddIncomeRequirementsModalOpen] = useState(false);
    const openAddIncomeRequirementsModal = () => setIsAddIncomeRequirementsModalOpen(true);
    const closeAddIncomeRequirementsModal = () => setIsAddIncomeRequirementsModalOpen(false);

    const [isAddFlexIncomeRequirementsModalOpen, setIsAddFlexIncomeRequirementsModalOpen] = useState(false);
    const openAddFlexIncomeRequirementsModal = () => setIsAddFlexIncomeRequirementsModalOpen(true);
    const closeAddFlexIncomeRequirementsModal = () => setIsAddFlexIncomeRequirementsModalOpen(false);

    const [isAddAnnuityModalOpen, setIsAddAnnuityModalOpen] = useState(false);
    const openAddAnnuityModal = () => setIsAddAnnuityModalOpen(true);
    const closeAddAnnuityModal = () => setIsAddAnnuityModalOpen(false);

    const [isTaxesModalOpen, setIsTaxesModalOpen] = useState(false);
    const openTaxesModal = () => setIsTaxesModalOpen(true);
    const closeTaxesModal = () => setIsTaxesModalOpen(false);

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const openSettingsModal = () => setIsSettingsModalOpen(true);
    const closeSettingsModal = () => setIsSettingsModalOpen(false);
   
    const [isDataChoiceModalOpen, setIsDataChoiceModalOpen] = useState(false);
    const openDataChoiceModal = () => setIsDataChoiceModalOpen(true);
    const closeDataChoiceModal = () => setIsDataChoiceModalOpen(false);

    const [isAssetDeductionModalOpen, setIsAssetDeductionModalOpen] = useState(false);
    const openAssetDeductionModal = () => {
      setIsAssetDeductionModalOpen(true);
      setDeductionEqGA(presetObject.asset_deduction[0].equity_ga); setDeductionBondGA(presetObject.asset_deduction[0].bonds_ga); setDeductionIndexGA(presetObject.asset_deduction[0].index_ga);
      setDeductionEqTE(presetObject.asset_deduction[0].equity_te); setDeductionBondTE(presetObject.asset_deduction[0].bonds_te); setDeductionIndexTE(presetObject.asset_deduction[0].index_te);
      setDeductionEqTD(presetObject.asset_deduction[0].equity_td); setDeductionBondTD(presetObject.asset_deduction[0].bonds_td); setDeductionIndexTD(presetObject.asset_deduction[0].index_td);
    }

    const closeAssetDeductionModal = () => setIsAssetDeductionModalOpen(false);  

    const [showDateModal, setShowDateModal] = useState(false)

    const getPlans = async () => {
      try {
          const response = await api.get('/getplans/');
          const data = response.data;
          setPlans(data.plans);
          openRetrieveModal();
      } catch (error) {
          // ideally would have a mechanism here to identify type 400 or 401 errors and auto relogin with openReLoginModal() but because the error is triggered inside axiom interceptor, the required error.response.status isn't passed to this point
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
        }
   };

    const handleOpenDeleteModal = (planIdNumber) => {
      setSelectedPlanId(planIdNumber); // Set the planId to delete
      setShowDeleteModal(true); // Show the confirmation modal
    };
  
    const handleCloseDeleteModal = () => {
      setSelectedPlanId(null); // Clear selected plan ID
      setShowDeleteModal(false); // Close the confirmation modal
    };

    const deletePlan = async (planIdNumber) => {
      try {
        // Send DELETE request as a POST request with planIdNumber in the body
        const response = await api.post('/deleteplan/', { plan_id: planIdNumber });
        // Update plans by removing the deleted plan
        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== planIdNumber));
        // Reset the state if the deleted plan was the currently active one
        if (planIdNumber === planId) {
          resetPlanId();
          resetPresetObject();
        }
      } catch (error) {     
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
      } 
      handleCloseDeleteModal();
    };

    const retrievePlan = async (planIdNumber) => {
        try {
          const response = await api.post('/retrieve/', { plan_id: planIdNumber });
          const data = response.data;
          if (response.status === 200) {  
            {setPresetObject({ 
              ['plan_title']: data.plan_title,
              ['plan_date']: data.plan_date,
              ['data_start_year']: data.data_start_year,
              ['data_end_year']: data.data_end_year,
              ['currency_set']: data.currency_set,
              ['geographic_set']: data.geographic_set,
              ['equity_tax']: data.equity_tax,
              ['bond_tax']: data.bond_tax,
              ['draw_tax']: data.draw_tax,
              ['bond_coupon']: data.bond_coupon,
              ['index_bond_coupon']: data.index_bond_coupon,
              ['asset_mix_equity']: data.asset_mix_equity,
              ['asset_mix_bond']: data.asset_mix_bond,
              ['asset_mix_index_bond']: data.asset_mix_index_bond,
              ['asset_mix_source']: data.asset_mix_source,
              ['data_option']: data.data_option,
              ['data_direction']: data.data_direction,
              ['dynamic_option']: data.dynamic_option,
              ['withdrawal_percent']: data.withdrawal_percent,
              ['target_withdrawal_percent']: data.target_withdrawal_percent,
              ['net_other_income']: data.net_other_income,
              ['yale_weighting']: data.yale_weighting,
              ['vanguard_decrease_floor']: data.vanguard_decrease_floor,
              ['vanguard_increase_ceiling']: data.vanguard_increase_ceiling,
              ['annuity_tax_rate']: data.annuity_tax_rate,
              ['annuity_tax_rate2']: data.annuity_tax_rate2,
              ['annuity_tax_rate3']: data.annuity_tax_rate3,
              ['fees']: data.fees,
              ['spread']: data.spread,
              ['equity_alpha']: data.equity_alpha,
              ['fixed_income_alpha']: data.fixed_income_alpha,
              ['start_simulation_age']: data.start_simulation_age,
              ['end_simulation_age']: data.end_simulation_age,
              ['circular_simulation']: data.circular_simulation,
              ['extraordinary_inflows']: data.extraordinary_inflows,
              ['state_pension']: data.state_pension,
              ['occupational_pension']: data.occupational_pension,
              ['annuity_pension']: data.annuity_pension,
              ['contribution_inflows']: data.contribution_inflows,
              ['income_requirements']: data.income_requirements,
              ['flex_income_requirements']: data.flex_income_requirements,
              ['asset_portfolio']: data.asset_portfolio,
              ['asset_deduction']: data.asset_deduction,
              ['apply_tax_to_inflation']: data.apply_tax_to_inflation,
            })};
            setPlanId(planIdNumber);
            closeRetrieveModal();
            // setModalmessage('Portfolio data retrieved.');
            // openModal()
          };
        } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If still not working, try logging out and logging in again.');
          openModal();
      };
    };

        const checkNumberPlans = async () => {
          try {
            const response = await api.get('/getplans/'); // Fetch the list of plans
            const data = response.data;
            const response2 = await api.get('/userinformation/');  // Fetch the user's permitted number of plans
            const permittedNumberOfPlans = response2.data
            const numberOfPlans = data.plans.length;
            if (numberOfPlans < permittedNumberOfPlans.userinformation.number_plans) {return true} else {return false}
          } catch (error) {
            // console.error('Error fetching plans:', error);
            return 0; // In case of an error, return 0 (or handle this case as you see fit)
          }
        };

        const savePlan = () => {
          if (presetObject.plan_date !== today) {setShowDateModal(true)} else {handleDateModalClose(false)}
          // setShowDateModal(true);
        };

        const handleDateModalClose = async (choice) => {
          setShowDateModal(false);
          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              let requestData;
              if (choice === true) {
                setPresetObject({['plan_date']: today})
                requestData = {
                  ...presetObject,
                  ...(planId && { planId }),  // Include planId if it's not null or undefined
                  plan_date: today  // Add this only if needed
                };
              } else {
                requestData = {
                  ...presetObject,
                  ...(planId && { planId })  // Include planId if it's not null or undefined
                };
              }
              const response = await api.post('/save/', requestData);
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved.');
                setPlanId(response.data.plan_id)
                openModal();
              }
            }
            } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
          }
          }
        };

        const copyPlan = async () => {
          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              const requestData = {
                ...presetObject,
                // ...(planId && { planId })  // Include planId if it's not null or undefined
              };
              const response = await api.post('/save/', requestData);
        
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved as a new plan.');
                setPlanId(response.data.plan_id)
                openModal();
              }}
            } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
          }
          }
        };

    const resetPlan = () => {
      resetPresetObject();
      resetPlanId();
      closeResetModal();
    };

    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioType, setPortfolioType] = useState('0');
    const [portfolioAmount, setPortfolioAmount] = useState('');
    const [portfolioEquity, setPortfolioEquity] = useState('');
    const [portfolioBonds, setPortfolioBonds] = useState('');
    const [portfolioIndex, setPortfolioIndex] = useState('');
    const [portfolioDate, setPortfolioDate] = useState(today);

    const handlePortfolioChange = () => {
      var portfolioweightsum = parseInt(portfolioEquity) + parseInt(portfolioBonds) + parseInt(portfolioIndex)
      if (ValidatePortfolioForm(portfolioName, portfolioAmount, portfolioEquity, portfolioBonds, portfolioIndex, portfolioDate, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({asset_portfolio: [...presetObject.asset_portfolio, {name: portfolioName, type: portfolioType, amount: portfolioAmount, equity: portfolioEquity, bonds: portfolioBonds, index: portfolioIndex, last_update_date: portfolioDate}]});
        setPortfolioName('');
        setPortfolioType('0');
        setPortfolioAmount('');
        setPortfolioEquity('');
        setPortfolioBonds('');
        setPortfolioIndex('');
        setPortfolioDate(today);
        closeAssetModal();
      };
    };    
    
    const [deductionEqGA, setDeductionEqGA] = useState(presetObject.asset_deduction[0].equity_ga);
    const [deductionBondGA, setDeductionBondGA] = useState(presetObject.asset_deduction[0].bonds_ga);
    const [deductionIndexGA, setDeductionIndexGA] = useState(presetObject.asset_deduction[0].index_ga);
    const [deductionEqTE, setDeductionEqTE] = useState(presetObject.asset_deduction[0].equity_te);
    const [deductionBondTE, setDeductionBondTE] = useState(presetObject.asset_deduction[0].bonds_te);
    const [deductionIndexTE, setDeductionIndexTE] = useState(presetObject.asset_deduction[0].index_te);
    const [deductionEqTD, setDeductionEqTD] = useState(presetObject.asset_deduction[0].equity_td);
    const [deductionBondTD, setDeductionBondTD] = useState(presetObject.asset_deduction[0].bonds_td);
    const [deductionIndexTD, setDeductionIndexTD] = useState(presetObject.asset_deduction[0].index_td);  

    const handleAssetDeductionChange = () => {
      // Check and set values to 0 if null or empty
      const finalDeductionEqGA = deductionEqGA === null || deductionEqGA === '' ? 0 : deductionEqGA;
      const finalDeductionBondGA = deductionBondGA === null || deductionBondGA === '' ? 0 : deductionBondGA;
      const finalDeductionIndexGA = deductionIndexGA === null || deductionIndexGA === '' ? 0 : deductionIndexGA;
      const finalDeductionEqTE = deductionEqTE === null || deductionEqTE === '' ? 0 : deductionEqTE;
      const finalDeductionBondTE = deductionBondTE === null || deductionBondTE === '' ? 0 : deductionBondTE;
      const finalDeductionIndexTE = deductionIndexTE === null || deductionIndexTE === '' ? 0 : deductionIndexTE;
      const finalDeductionEqTD = deductionEqTD === null || deductionEqTD === '' ? 0 : deductionEqTD;
      const finalDeductionBondTD = deductionBondTD === null || deductionBondTD === '' ? 0 : deductionBondTD;
      const finalDeductionIndexTD = deductionIndexTD === null || deductionIndexTD === '' ? 0 : deductionIndexTD;
    
      // Proceed with validation after setting defaults to zero
      if (ValidateAssetDeduction(
        finalDeductionEqGA, finalDeductionBondGA, finalDeductionIndexGA, 
        finalDeductionEqTE, finalDeductionBondTE, finalDeductionIndexTE, 
        finalDeductionEqTD, finalDeductionBondTD, finalDeductionIndexTD, 
        setErrors, setIsModalOpen, setModalmessage)) {
          setPresetObject({
            asset_deduction: [{
              equity_ga: finalDeductionEqGA, 
              bonds_ga: finalDeductionBondGA, 
              index_ga: finalDeductionIndexGA, 
              equity_te: finalDeductionEqTE, 
              bonds_te: finalDeductionBondTE, 
              index_te: finalDeductionIndexTE, 
              equity_td: finalDeductionEqTD, 
              bonds_td: finalDeductionBondTD, 
              index_td: finalDeductionIndexTD
            }]
          });
          closeAssetDeductionModal();
      }
    };

    const [extraName, setExtraName] = useState('');
    const [extraYear, setExtraYear] = useState('');
    const [extraAmount, setExtraAmount] = useState('');
    const [extraIndex, setExtraIndex] = useState('1');
    const [extraIncrease, setExtraIncrease] = useState('0');
    const [extraType, setExtraType] = useState('0');
    const [extraDate, setExtraDate] = useState(today);

    const handleExtraInflowChange = () => {
      if (ValidateExtraContributionForm(presetObject, extraName, extraYear, extraAmount, extraIncrease, extraDate, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({extraordinary_inflows: [...presetObject.extraordinary_inflows, {name: extraName, year: extraYear, amount: extraAmount, index: extraIndex, increase: extraIncrease, type: extraType, last_update_date: extraDate}]});
        setExtraName('');
        setExtraYear('');
        setExtraAmount('');
        setExtraIndex('1');
        setExtraIncrease(0);
        setExtraType('0');
        setExtraDate(today);
        closeExtraordinaryModal();
      };
    };

    const [stateName, setStateName] = useState('');
    const [stateYear, setStateYear] = useState('');
    const [stateAmount, setStateAmount] = useState('');
    const [stateIndex, setStateIndex] = useState('1');
    const [stateIncrease, setStateIncrease] = useState('0');
    const [stateDate, setStateDate] = useState(today);

    const handleStatePensionChange = () => {
      if (ValidateStatePensionForm(presetObject, stateName, stateYear, stateAmount, stateIncrease, stateDate, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({state_pension: [...presetObject.state_pension, {name: stateName, year: stateYear, amount: stateAmount, index: stateIndex, increase: stateIncrease, last_update_date: stateDate}]});
        setStateName('');
        setStateYear('');
        setStateAmount('');
        setStateIndex('1');
        setStateIncrease(0);
        setStateDate(today);
        closeStateModal();
      };
    };

    const [occupationalName, setOccupationalName] = useState('');
    const [occupationalYear, setOccupationalYear] = useState('');
    const [occupationalAmount, setOccupationalAmount] = useState('');
    const [occupationalIndex, setOccupationalIndex] = useState('1');
    const [occupationalIncrease, setOccupationalIncrease] = useState('0');
    const [occupationalDate, setOccupationalDate] = useState(today);

    const handleOccupationalPensionChange = () => {
        if (ValidateOccupationalPensionForm(presetObject, occupationalName, occupationalYear, occupationalAmount, occupationalIncrease, occupationalDate, setErrors, setIsModalOpen, setModalmessage)) {
          setPresetObject({occupational_pension: [...presetObject.occupational_pension, {name: occupationalName, year: occupationalYear, amount: occupationalAmount, index: occupationalIndex, increase: occupationalIncrease, last_update_date: occupationalDate}]});
          setOccupationalName('');
          setOccupationalYear('');
          setOccupationalAmount('');
          setOccupationalIndex('1');
          setOccupationalIncrease(0);
          setOccupationalDate(today);
          closeOccupationalModal();
      };
    };  

    const [annuityName, setAnnuityName] = useState('');
    const [annuityYear, setAnnuityYear] = useState('');
    const [annuityAmount, setAnnuityAmount] = useState('');
    const [annuityPrice, setAnnuityPrice] = useState('');
    const [annuityIndex, setAnnuityIndex] = useState('1');
    const [annuityIncrease, setAnnuityIncrease] = useState('0');
    const [annuityDate, setAnnuityDate] = useState(today);

    const handleAnnuityPensionChange = () => {
      if (ValidateAnnuityForm(presetObject, annuityName, annuityYear, annuityAmount, annuityPrice, annuityIncrease, annuityDate, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({annuity_pension: [...presetObject.annuity_pension, {name: annuityName, year: annuityYear, amount: annuityAmount, price: annuityPrice, index: annuityIndex, increase: annuityIncrease, last_update_date: annuityDate}]});
        setAnnuityName('');
        setAnnuityYear('');
        setAnnuityAmount('');
        setAnnuityPrice('');
        setAnnuityIndex('1');
        setAnnuityIncrease(0);
        setAnnuityDate(today);
        closeAnnuityModal();
      };
    };

    const [contributionName, setContributionName] = useState('');
    const [contributionYear, setContributionYear] = useState('');
    const [contributionYearEnd, setContributionYearEnd] = useState('');
    const [contributionAmount, setContributionAmount] = useState('');
    const [contributionIndex, setContributionIndex] = useState('1');
    const [contributionIncrease, setContributionIncrease] = useState('0');
    const [contributionType, setContributionType] = useState('0');
    const [contributionDate, setContributionDate] = useState('0');

    const handleContributionChange = () => {
        if (ValidateContributionForm(presetObject, contributionName, contributionYear, contributionYearEnd, contributionAmount, contributionIncrease, contributionDate, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({contribution_inflows: [...presetObject.contribution_inflows, {name: contributionName, year: contributionYear, yearend: contributionYearEnd, amount: contributionAmount, index: contributionIndex, increase: contributionIncrease, type: contributionType, last_update_date: contributionDate}]});
        setContributionName('');
        setContributionYear('');
        setContributionYearEnd('');
        setContributionAmount('');
        setContributionIndex('1');
        setContributionIncrease(0);
        setContributionType('0');
        setContributionDate(today);
        closeContributionModal();
      };
    };  

    const [incomeName, setIncomeName] = useState('');
    const [incomeYear, setIncomeYear] = useState('');
    const [incomeYearEnd, setIncomeYearEnd] = useState('');
    const [incomeAmount, setIncomeAmount] = useState('');
    const [incomeIndex, setIncomeIndex] = useState('1');
    const [incomeIncrease, setIncomeIncrease] = useState('0');
    const [incomeIncrease2, setIncomeIncrease2] = useState('0');
    const [incomeDate, setIncomeDate] = useState(today);

    const handleIncomeChange = () => {
        if (ValidateIncomeForm(presetObject, incomeName, incomeYear, incomeYearEnd, incomeAmount, incomeIncrease, incomeIncrease2, incomeDate, setErrors, setIsModalOpen, setModalmessage)) { 
        if (incomeIndex === '0' || incomeIndex === '1') {setPresetObject({income_requirements: [...presetObject.income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease, last_update_date: incomeDate}]})} else {setPresetObject({...presetObject, income_requirements: [...presetObject.income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease2, last_update_date: incomeDate}]})};
        setIncomeName('');
        setIncomeYear('');
        setIncomeYearEnd('');
        setIncomeAmount('');
        setIncomeIndex('1');
        setIncomeIncrease(0);
        setIncomeIncrease2(0);
        setIncomeDate(today);
        closeIncomeModal();
      };
    }; 

    const [flexIncomeName, setFlexIncomeName] = useState('');
    const [flexIncomeYear, setFlexIncomeYear] = useState('');
    const [flexIncomeYearEnd, setFlexIncomeYearEnd] = useState('');
    const [flexIncomeAmount, setFlexIncomeAmount] = useState('');
    const [flexIncomeIndex, setFlexIncomeIndex] = useState('1');
    const [flexIncomeIncrease, setFlexIncomeIncrease] = useState('0');
    const [flexIncomeDate, setFlexIncomeDate] = useState(today);

    const handleFlexIncomeChange = () => {
        if (ValidateFlexIncomeForm(presetObject, flexIncomeName, flexIncomeYear, flexIncomeYearEnd, flexIncomeAmount, flexIncomeIncrease, flexIncomeDate, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({flex_income_requirements: [...presetObject.flex_income_requirements, {name: flexIncomeName, year: flexIncomeYear, yearend: flexIncomeYearEnd, amount: flexIncomeAmount, index: flexIncomeIndex, increase: flexIncomeIncrease, last_update_date: flexIncomeDate}]});
        setFlexIncomeName('');
        setFlexIncomeYear('');
        setFlexIncomeYearEnd('');
        setFlexIncomeAmount('');
        setFlexIncomeIndex('1');
        setFlexIncomeIncrease(0);
        setFlexIncomeDate(today);
        closeBonusModal();
      };
    }; 

    const handlePortfolioDeleteItem = (index) => {
      setPresetObject({
        asset_portfolio: presetObject.asset_portfolio.filter((_, i) => i !== index),
      });
    };

    const handlePortfolioModityItem = (index) => {
      const item = presetObject.asset_portfolio[index];
      setPortfolioName(item.name);
      setPortfolioType(item.type); 
      setPortfolioAmount(item.amount);
      setPortfolioEquity(item.equity);
      setPortfolioBonds(item.bonds);
      setPortfolioIndex(item.index);
      setPortfolioDate(item.last_update_date);
      setPresetObject({
        asset_portfolio: presetObject.asset_portfolio.filter((_, i) => i !== index),
      });
      openAssetModal();
    };   

    const handleExtraDeleteItem = (index) => {
      setPresetObject({
        extraordinary_inflows: presetObject.extraordinary_inflows.filter((_, i) => i !== index),
      });
    };

    const handleExtraModityItem = (index) => {
      const item = presetObject.extraordinary_inflows[index];
      setExtraName(item.name);
      setExtraYear(item.year); 
      setExtraAmount(item.amount);
      setExtraIndex(item.index);
      setExtraIncrease(item.increase);
      setExtraType(item.type);
      setExtraDate(item.last_update_date);
      setPresetObject({
        extraordinary_inflows: presetObject.extraordinary_inflows.filter((_, i) => i !== index),
      });
      openExtraordinaryModal();
    };

    const handleStatePensionDeleteItem = (index) => {
      setPresetObject({
        state_pension: presetObject.state_pension.filter((_, i) => i !== index),
      });
    };

    const handleStatePensionModityItem = (index) => {
      const item = presetObject.state_pension[index];
      setStateName(item.name);
      setStateYear(item.year); 
      setStateAmount(item.amount);
      setStateIndex(item.index);
      setStateIncrease(item.increase);
      setStateDate(item.last_update_date);
      setPresetObject({
        state_pension: presetObject.state_pension.filter((_, i) => i !== index),
      });
      openStateModal();
    };

    const handleOccupationalPensionDeleteItem = (index) => {
      setPresetObject({
        occupational_pension: presetObject.occupational_pension.filter((_, i) => i !== index),
      });
    };

    const handleOccupationalPensionModityItem = (index) => {
      const item = presetObject.occupational_pension[index];
      setOccupationalName(item.name);
      setOccupationalYear(item.year); 
      setOccupationalAmount(item.amount);
      setOccupationalIndex(item.index);
      setOccupationalIncrease(item.increase);
      setOccupationalDate(item.last_update_date);
      setPresetObject({
        occupational_pension: presetObject.occupational_pension.filter((_, i) => i !== index),
      });
      openOccupationalModal();
    };

    const handleAnnuityPensionDeleteItem = (index) => {
      setPresetObject({
        annuity_pension: presetObject.annuity_pension.filter((_, i) => i !== index),
      });
    };

    const handleAnnuityPensionModityItem = (index) => {
      const item = presetObject.annuity_pension[index];
      setAnnuityName(item.name);
      setAnnuityYear(item.year); 
      setAnnuityAmount(item.amount);
      setAnnuityPrice(item.price);
      setAnnuityIndex(item.index);
      setAnnuityIncrease(item.increase);
      setAnnuityDate(item.last_update_date);
      setPresetObject({
        annuity_pension: presetObject.annuity_pension.filter((_, i) => i !== index),
      });
      openAnnuityModal();
    };

    const handleContributionDeleteItem = (index) => {
      setPresetObject({
        contribution_inflows: presetObject.contribution_inflows.filter((_, i) => i !== index),
      });
    };

    const handleContributionModityItem = (index) => {
      const item = presetObject.contribution_inflows[index];
      setContributionName(item.name);
      setContributionYear(item.year); 
      setContributionYearEnd(item.yearend);
      setContributionAmount(item.amount);
      setContributionIndex(item.index);
      setContributionIncrease(item.increase);
      setContributionType(item.type);
      setAnnuityDate(item.last_update_date);
      setPresetObject({
        contribution_inflows: presetObject.contribution_inflows.filter((_, i) => i !== index),
      });
      openContributionModal();
    };

    const handleIncomeDeleteItem = (index) => {
      setPresetObject({
        income_requirements: presetObject.income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleIncomeModityItem = (index) => {
      const item = presetObject.income_requirements[index];
      setIncomeName(item.name);
      setIncomeYear(item.year); 
      setIncomeYearEnd(item.yearend);
      setIncomeAmount(item.amount);
      setIncomeIndex(item.index);
      setIncomeIncrease(item.increase);
      setIncomeIncrease2(item.increase2);
      setIncomeDate(item.last_update_date);
      setPresetObject({
        income_requirements: presetObject.income_requirements.filter((_, i) => i !== index),
      });
      openIncomeModal();
    };

    const handleFlexIncomeDeleteItem = (index) => {
      setPresetObject({
        flex_income_requirements: presetObject.flex_income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleFlexIncomeModityItem = (index) => {
      const item = presetObject.flex_income_requirements[index];
      setFlexIncomeName(item.name);
      setFlexIncomeYear(item.year); 
      setFlexIncomeYearEnd(item.yearend);
      setFlexIncomeAmount(item.amount);
      setFlexIncomeIndex(item.index);
      setFlexIncomeIncrease(item.increase);
      setFlexIncomeDate(item.last_update_date);
      setPresetObject({
        flex_income_requirements: presetObject.flex_income_requirements.filter((_, i) => i !== index),
      });
      openBonusModal();
    };

    const handleObjectChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: parseFloat(value),})) {
        setPresetObject({[property]: parseFloat(value),});
      };
    };

    const handleDateChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const handleTextChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: value,})) {
        setPresetObject({[property]: value,});
      };
    };

    const handleSelectChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const handleDataCalibrationChange = (property, value) => {
      if (value === "backUSDshiller") {setPresetObject({['data_start_year']: 1870, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "backUSDshort") {setPresetObject({['data_start_year']: 1925, ['data_end_year']: 1995, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'back', ['circular_simulation']: "0",[property]: value,})}
      else if (value === "backUSDglobal") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'GLOBAL', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "forwardUSD") {setPresetObject({['data_start_year']: 1870, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "forwardUSDglobal") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'GLOBAL', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "back") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'GBP', ['geographic_set']: 'GLOBAL', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'GBP', ['geographic_set']: 'GLOBAL', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})};    
    };

    useEffect(() => {
      presetObject.geographic_set === 'DOMESTIC' ? setPresetObject({['data_start_year']: 1870}) : setPresetObject({['data_start_year']: 1899})
    }, [presetObject.geographic_set])

    const [assetDeductionTotal, setAssetDeductionTotal] = useState(0)

    useEffect(() => {
      setAssetDeductionTotal(
        presetObject.asset_deduction[0]['equity_ga'] + 
        presetObject.asset_deduction[0]['bonds_ga'] + 
        presetObject.asset_deduction[0]['index_ga'] + 
        presetObject.asset_deduction[0]['equity_te'] + 
        presetObject.asset_deduction[0]['bonds_te'] + 
        presetObject.asset_deduction[0]['index_te'] + 
        presetObject.asset_deduction[0]['equity_td'] + 
        presetObject.asset_deduction[0]['bonds_td'] + 
        presetObject.asset_deduction[0]['index_td']
      )
    }, [presetObject.asset_deduction[0]])

    const getSimulationData = async () => {
      let apiroute = ""
      apiroute = "getsimulationdata"
      try {
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'GET',
        headers: {'HTTP_API_KEY': 'testkeytestkey99'},
      })
      const data = await response.json();
      setPresetObject({['historic_asset_return_data']: data.historic_dataset, ['forward_asset_return_data']: data.forward_dataset, ['mortality_data']: data.mortality_dataset});
      } catch (error) {console.log(error)}
    }

    const handleSubmit = async () => {
      let apiroute = ""
      if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
      setLoading4(true);
      apiroute = "simulation";
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: {'HTTP_API_KEY': 'testkeytestkey99'},
        body: JSON.stringify(presetObject),
      });
      const data = await response.json();
      setPortfoliovaluepercentiles(data.portfolio_all_value_streams_percentiles);
      setIncomepercentiles(data.income_all_streams_percentiles);     
      setResult(data.simulation_fails * 100);
      setDecresult(data.portfolio_end_value_decile);
      setAllresult(data.portfolio_all_value_streams);
      setAllresultGA(data.portfolio_all_value_streams_ga);
      setAllresultTE(data.portfolio_all_value_streams_te);
      setAllresultTD(data.portfolio_all_value_streams_td);
      setAlldrawstreams(data.income_all_streams);
      setDrawstreamsbytype(data.median_income_by_type_all_cycles);
      setBestswrs(data.max_zero_fail_SWR_by_cycle);
      setAveragedraw(data.avg_income);
      setAverageadjusteddraw(data.avg_mort_adjusted_income);
      setSafefunding(data.max_SWR_by_simulation_year);
      setSafefundinglevel(data.safe_funding_level);
      setDrawhistdata({
        'histogram':data.income_histogram_data,
        'bins':data.income_histogram_edges,
      });
      setSumdrawadjustedavg(data.sum_mort_adjusted_discounted_income);
      setResultyears(data.simulation_years);
      setYearsForChart(data.years_for_chart);
      setPortfolioTotal(data.portfolio_total);
      setYearsForChartShort(data.years_for_chart_short);
      setSafeFundingPercentiles(data.safe_funding_level_by_cycle_percentiles);
      setSafeWithdrawalPercentiles(data.safe_withdrawal_rate_by_cycle_percentiles);

      // below is to make sure asset mix equals exactly 100 after rounding
      if (presetObject.asset_mix_source === "0") {
        setPresetObject({
          asset_mix_equity: parseFloat((data.portfolio_actual_asset_mix[0] * 100).toFixed(1)),
          asset_mix_bond: parseFloat((data.portfolio_actual_asset_mix[1] * 100).toFixed(1)),
          asset_mix_index_bond: 100 - (parseFloat((data.portfolio_actual_asset_mix[0] * 100).toFixed(1)) + parseFloat((data.portfolio_actual_asset_mix[1] * 100).toFixed(1))),
          // asset_mix_equity: (data.portfolio_actual_asset_mix[0] * 100),
          // asset_mix_bond: (data.portfolio_actual_asset_mix[1] * 100),
          // asset_mix_index_bond: (data.portfolio_actual_asset_mix[2] * 100),
        });
      }
      } catch (error) {setModalmessage('Something went wrong.'); openModal();} finally {setLoading4(false)}
    };
    };

    const changeOptimalMixMax = async () => {
      let apiroute = ""
      if (ValidateFormOptimisation(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
        setLoading2(true);
        setPresetObject({['asset_mix_source']: "1"});
        apiroute = "mixopt";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {'HTTP_API_KEY': 'testkeytestkey99'},
          body: JSON.stringify(presetObject),
        });
        const data = await response.json();
        setPresetObject({
          ['asset_mix_equity']: data.optimised_equity_max,
          ['asset_mix_bond']: data.optimised_bond_max,
          ['asset_mix_index_bond']: data.optimised_index_bond_max,
        });
        setOptrun(!optrun);
        if (data.optimised_failure_max > 0) {
          setModalmessage('Asset mix optimised to give lowest possible but >0% failure rate. Review portfolio starting value and/or withdrawal level.');
          openModal()} else {setModalmessage('Asset mix updated.'); openModal()};
        } catch (error) {setModalmessage('Something went wrong.'); openModal();}
        finally {setLoading2(false)} 
      };
    };
    const changeOptimalMixMin = async () => {
      let apiroute = ""
      if (ValidateFormOptimisation(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
        setLoading3(true);
        setPresetObject({['asset_mix_source']: "1"});
        apiroute = "mixopt";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {'HTTP_API_KEY': 'testkeytestkey99'},
          body: JSON.stringify(presetObject),
        });
        const data = await response.json();
        setPresetObject({
          ['asset_mix_equity']: data.optimised_equity_min, 
          ['asset_mix_bond']: data.optimised_bond_min, 
          ['asset_mix_index_bond']: data.optimised_index_bond_min,});
        setOptrun(!optrun);
        if (data.optimised_failure_min > 0) {
          setModalmessage('Asset mix optimised to give lowest possible but >0% failure rate. Review portfolio starting value and/or withdrawal level.');
          openModal()} else {setModalmessage('Asset mix updated.'); openModal()};
        } catch (error) {setModalmessage('Something went wrong.'); openModal();} 
        finally {setLoading3(false)} 
      };
    };

    const runMixMaxOptimisationAndReCalc = async () => {
      await changeOptimalMixMax();
    }

    const runMixMinOptimisationAndReCalc = async () => {
      await changeOptimalMixMin();
    }
    
    useEffect(() => {
      getSimulationData();
    }, []);

    useEffect(() => {
      if (isInitialRender) {setIsInitialRender(false)} else {handleSubmit()}
    }, [optrun]);

    useEffect(() => {
      if (presetObject.data_direction == 'back' && presetObject.asset_mix_index_bond > 0) {
        {setModalmessage('No allocation to index bonds possible with historic backtesting data selected. Existing index linked bond allocation moved to conventional bond allocation.'); openModal()};
      setPresetObject({['asset_mix_bond']: parseFloat(presetObject.asset_mix_index_bond + presetObject.asset_mix_bond), ['asset_mix_index_bond']: parseFloat(0),});
    }}, [presetObject.data_direction]);

    useEffect(() => {
      setYearList(Array.from({ length: presetObject.data_end_year - presetObject.data_start_year + 1}, (_, index) => presetObject.data_start_year + index));
    }, [presetObject.data_option]);

    // this is to auto run the simulation when directed from the intro page after filling in the plan questions.  but commented out as seems to be causing errors.
    // useEffect(() => {
    //   if (location.state?.triggerSubmit) {
    //     handleSubmit();
    //   }
    // }, [location.state]);

    useEffect(() => {
      
      var eq_general = 0.0 
      var eq_taxexempt = 0.0
      var eq_taxdeferred = 0.0
      var bd_general = 0.0;
      var bd_taxexempt = 0.0;
      var bd_taxdeferred = 0.0;
      var ix_general = 0.0;
      var ix_taxexempt = 0.0;
      var ix_taxdeferred = 0.0;
      var total = 0.0;
      var eq_general_percent = 0
      var bd_general_percent = 0
      var ix_general_percent = 0
      var eq_taxexempt_percent = 0
      var bd_taxexempt_percent = 0
      var ix_taxexempt_percent = 0
      var eq_taxdeferred_percent = 0
      var bd_taxdeferred_percent = 0
      var ix_taxdeferred_percent = 0
      var general_percent = 0
      var taxexempt_percent = 0 
      var taxdeferred_percent = 0
      var eq_percent = 0
      var bd_percent = 0
      var ix_percent = 0
      var total_percent = 0
      var total_general = 0
      var total_taxexempt = 0
      var total_taxdeferred = 0
      var total_equity = 0
      var total_bonds = 0
      var total_index = 0

      for (let i = 0; i < presetObject.asset_portfolio.length; i++) {
        if (presetObject.asset_portfolio[i].type === "0") 
        {
        eq_general = eq_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_general = bd_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_general = ix_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        else if (presetObject.asset_portfolio[i].type === "1")
        {
        eq_taxexempt = eq_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_taxexempt = bd_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_taxexempt = ix_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        else
        {
        eq_taxdeferred = eq_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_taxdeferred = bd_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_taxdeferred = ix_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        total = total + parseFloat(presetObject.asset_portfolio[i].amount)
      };

      eq_general = Math.max(0, eq_general - presetObject.asset_deduction[0].equity_ga);
      bd_general = Math.max(0, bd_general - presetObject.asset_deduction[0].bonds_ga);
      ix_general = Math.max(0, ix_general - presetObject.asset_deduction[0].index_ga);
      eq_taxexempt = Math.max(0, eq_taxexempt - presetObject.asset_deduction[0].equity_te);
      bd_taxexempt = Math.max(0, bd_taxexempt - presetObject.asset_deduction[0].bonds_te);
      ix_taxexempt = Math.max(0, ix_taxexempt - presetObject.asset_deduction[0].index_te);
      eq_taxdeferred = Math.max(0, eq_taxdeferred - presetObject.asset_deduction[0].equity_td);
      bd_taxdeferred = Math.max(0, bd_taxdeferred - presetObject.asset_deduction[0].bonds_td);
      ix_taxdeferred = Math.max(0, ix_taxdeferred - presetObject.asset_deduction[0].index_td);

      total_general = eq_general + bd_general + ix_general
      total_taxexempt = eq_taxexempt + bd_taxexempt + ix_taxexempt
      total_taxdeferred = eq_taxdeferred + bd_taxdeferred + ix_taxdeferred
      total_equity = eq_general + eq_taxexempt + eq_taxdeferred
      total_bonds = bd_general + bd_taxexempt + bd_taxdeferred
      total_index = ix_general + ix_taxexempt + ix_taxdeferred
      total = eq_general + bd_general + ix_general + eq_taxexempt + bd_taxexempt + ix_taxexempt + eq_taxdeferred + bd_taxdeferred + ix_taxdeferred 

      if (total !== 0) {
        eq_general_percent = eq_general / total * 100
        bd_general_percent = bd_general / total * 100
        ix_general_percent = ix_general / total * 100
        eq_taxexempt_percent = eq_taxexempt / total * 100
        bd_taxexempt_percent = bd_taxexempt / total * 100
        ix_taxexempt_percent = ix_taxexempt / total * 100
        eq_taxdeferred_percent = eq_taxdeferred / total * 100
        bd_taxdeferred_percent = bd_taxdeferred / total * 100
        ix_taxdeferred_percent = ix_taxdeferred / total * 100
        general_percent = total_general / total * 100
        taxexempt_percent = total_taxexempt / total * 100
        taxdeferred_percent = total_taxdeferred / total * 100
        eq_percent = total_equity / total * 100
        bd_percent = total_bonds / total * 100
        ix_percent = total_index / total * 100
        total_percent = total / total * 100
      } else
      {
        eq_general_percent = 0
        bd_general_percent = 0
        ix_general_percent = 0
        eq_taxexempt_percent = 0
        bd_taxexempt_percent = 0
        ix_taxexempt_percent = 0
        eq_taxdeferred_percent = 0
        bd_taxdeferred_percent = 0
        ix_taxdeferred_percent = 0
        general_percent = 0
        taxexempt_percent = 0 
        taxdeferred_percent = 0
        eq_percent = 0
        bd_percent = 0
        ix_percent = 0
        total_percent = 0
      }
      setInPortfolioEquity({['general'] : eq_general, ['tax_exempt'] : eq_taxexempt, ['tax_deferred'] : eq_taxdeferred, ['total'] : total_equity, ['general_percent'] : eq_general_percent, ['tax_exempt_percent'] : eq_taxexempt_percent, ['tax_deferred_percent'] : eq_taxdeferred_percent, ['total_percent'] : eq_percent});
      setInPortfolioBonds({['general'] : bd_general, ['tax_exempt'] : bd_taxexempt, ['tax_deferred'] : bd_taxdeferred, ['total'] : total_bonds, ['general_percent'] : bd_general_percent, ['tax_exempt_percent'] : bd_taxexempt_percent, ['tax_deferred_percent'] : bd_taxdeferred_percent, ['total_percent'] : bd_percent});
      setInPortfolioIndex({['general'] : ix_general, ['tax_exempt'] : ix_taxexempt, ['tax_deferred'] : ix_taxdeferred, ['total'] : total_index, ['general_percent'] : ix_general_percent, ['tax_exempt_percent'] : ix_taxexempt_percent, ['tax_deferred_percent'] : ix_taxdeferred_percent, ['total_percent'] : ix_percent});
      setInPortfolioTotal({['general'] : total_general, ['tax_exempt'] : total_taxexempt, ['tax_deferred'] : total_taxdeferred, ['total'] : total, ['general_percent'] : general_percent, ['tax_exempt_percent'] : taxexempt_percent, ['tax_deferred_percent'] : taxdeferred_percent, ['total_percent'] : total_percent});
      setPortfolioTotal(total);
    }, [presetObject.asset_portfolio, presetObject.asset_deduction])

    const [dataForChart, setDataForChart] = useState(allresult);
    const [unitsForYAxis, setUnitsForYAxis] = useState(yearsForChart);
    const [titleForChart, setTitleForChart] = useState('Development of portfolio value through each back-test simulation cycle ($,\u00A3 current value)');
    const [yaxisTitleForChart, setyAxisTitleForChart] = useState('At Beginning Age');
    const [percentileSettingForChart, setPercentileSettingForChart] = useState(false);
    const [multipleLinesSettingForChart, setMultipleLinesSettingForChart] = useState(true);
    const [flagPortfolioValueDistChart, setFlagPortfolioValueDistChart] = useState(true);
    
    useEffect(() => {
      if (switchvalue === 1 && switchvalue4 === 1) {setDataForChart(allresult); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart('Development of portfolio value through each back-test simulation cycle ($,\u00A3 current value)'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)} 
      else if (switchvalue === 1 && switchvalue4 === 2) {setDataForChart(portfoliovaluepercentiles); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart('Development of portfolio value through each back-test simulation cycle by percentile ($,\u00A3 current value)'); setPercentileSettingForChart(true); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 1 && switchvalue4 === 3) {setDataForChart(allresultGA); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart('Development of GA portfolio value through each back-test simulation cycle ($,\u00A3 current value)'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 1 && switchvalue4 === 4) {setDataForChart(allresultTE); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart('Development of TE portfolio value through each back-test simulation cycle ($,\u00A3 current value)'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 1 && switchvalue4 === 5) {setDataForChart(allresultTD); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart('Development of TD portfolio value through each back-test simulation cycle ($,\u00A3 current value)'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 4 && switchvalue2 === 1) {setDataForChart(alldrawstreams); setyAxisTitleForChart('In year beginning at age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart('Development of annual income/withdrawal through each back-test simulation cycle (current $,\u00A3 net of tax)'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 4 && switchvalue2 === 2) {setDataForChart(incomepercentiles); setyAxisTitleForChart('In year beginning at age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart('Development of annual income/withdrawal through each back-test simulation cycle by percentile (current $,\u00A3 net of tax)'); setPercentileSettingForChart(true); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 3 && switchvalue3 === 1) {setDataForChart(bestswrs); setyAxisTitleForChart('First year of back-testing history'); setUnitsForYAxis(resultyears); setTitleForChart('Safe initial funding level ($,\u00A3 current value) by back-test cycle for constant withdrawal strategy'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(false); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 3 && switchvalue3 === 2) {setDataForChart(safefundinglevel); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart('Maximum safe withdrawal rate (max withdrawal amount / min safe funding level) by year into simulation'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(false); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 3 && switchvalue3 === 3) {setDataForChart(safefunding); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart('Minimum safe funding level ($,\u00A3 current value) by year into simulation'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(false); setFlagPortfolioValueDistChart(false)};
    }, [switchvalue, switchvalue2, switchvalue3, switchvalue4, allresult, portfoliovaluepercentiles, allresultGA, allresultTE, allresultTD, alldrawstreams, incomepercentiles, bestswrs, safefundinglevel, safefunding, yearsForChart, yearsForChart]);

    return (

      <div>
        	<Helmet>
            <title>Retire Smart Calc - Retirement Portfolio Simulation Calculator</title>
            <meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
            <link rel="canonical" href="https://www.retiresmartcalc.com/#/calc"></link>
            <link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
            <script type="application/ld+json">
            {JSON.stringify(webpageData)}
            </script>
          </Helmet>

      
      <Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
        <Row style={{ display: 'flex', gap: '0px' }}>
          <Col sm={4} className={`border border-secondary p-3 mt-2 mb-2 ${isSmallScreen ? '' : 'custom-scrollbar-col'}`} style={isSmallScreen ? {} : { overflowY: 'auto', height: '100vh' }}>

          <div className="d-flex align-items-center">
            <p className="lead mb-0">Plan:</p>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">Inputs are specific to each plan. You can save and retrieve plans by registering for a user account.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>


          <PlanButtons 
          logInState={logInState} 
          userId={userId} 
          getPlans={getPlans} 
          copyPlan={copyPlan}
          savePlan={savePlan} 
          openResetModal={openResetModal} 
          planAlreadySaved={planId}
          />

            <Row>
              <Form.Group>
                <Form.Label htmlFor="plan_title" className="fw-light small">Plan title:</Form.Label>
                <Form.Control type="text" name="plan_title" id="plan_title" value={presetObject.plan_title} onChange={(e) => handleTextChange('plan_title', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
                {errors.plan_title && <Alert className="alert alert-danger">{errors.plan_title}</Alert>}
              </Form.Group>              
            </Row>

            <Row>
            <Form.Group as={Col}>
            <FormTemplateInteger formlabel="Age at start of simulation:" formname="start_simulation_age" type="number" overlaytext="Enter the starting age for the simulation (e.g. current age or expected age at retirement)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors}/>
            </Form.Group>
            <Form.Group as={Col}>
            <FormTemplateInteger formlabel="Age at end of simulation:" formname="end_simulation_age" type="number" overlaytext="Age to which you wish to plan to (e.g. 100 years)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors}/>
            </Form.Group>
            </Row>

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Current retirement savings:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Add existing portfolio of savings designated for retirement together with any expected state pension / social security and (defined benefit) occupational pension income.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1rem' }} /></span>
            </OverlayTrigger>
            </div>


            {/* <div className="my-2" /> */}
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddAssetModal}>Add asset to portfolio
              <span className="badge-space"><Badge bg="info">{presetObject.asset_portfolio.length ? presetObject.asset_portfolio.length : null}</Badge></span>
              </Button>
            </div>
            <div className="my-2"></div>
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddStateModal}>Add state pension
              <span className="badge-space"><Badge bg="info">{presetObject.state_pension.length ? presetObject.state_pension.length : null}</Badge></span>
              </Button>
            </div>
            <div className="my-2"></div>
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddOccupationModal}>Add occupational pension
              <span className="badge-space"><Badge bg="info">{presetObject.occupational_pension.length ? presetObject.state_pension.length : null}</Badge></span>
              </Button>
            </div>

            {/* Asset Modal */}
            <Modal show={isAddAssetModalOpen} onHide={closeAddAssetModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Asset Portfolio</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

              <div className="my-2" />
              <PortfolioBreakdownPopup show={isPortfolioModalOpen} onClose={closePortfolioModal} data={portfolioTotal} data2={inPortfolioEquity} data3={inPortfolioBonds} data4={inPortfolioIndex} data5={inPortfolioTotal}/>
              <div className="mt-4" />

              <Row>
              {presetObject.asset_portfolio
              .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
              .map((item) => (
                <Col key={item.originalIndex} md={3} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        {item.amount === null || item.amount === undefined ? (
                          <>''</>
                        ) : (
                          <p className={isSmallScreen ? 'small' : 'small'}>
                            Amount ($,£): {item.amount.toLocaleString()}
                          </p>
                        )}
                      </Card.Text>
                      <Card.Text className="small tight-spacing">Type: {item.type === '0' ? 'General' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}</Card.Text>
                      <Card.Text className="small tight-spacing">Equity: {item.equity}</Card.Text>
                      <Card.Text className="small tight-spacing">Conventional Bonds: {item.bonds}</Card.Text>
                      <Card.Text className="small tight-spacing">Index Bonds: {item.index}</Card.Text>
                      <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                      <Button variant="danger" onClick={() => handlePortfolioDeleteItem(item.originalIndex)}>Delete</Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handlePortfolioModityItem(item.originalIndex)}>Modify</Button>                      
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
              <div>
                <Button variant="success" className="me-1" onClick={openAssetModal}>Add Asset</Button>
                <Button variant="success" className="me-1" onClick={openPortfolioModal}>Show Portfolio Breakdown</Button>
                <Button variant="success" className="me-1" onClick={openAssetDeductionModal}>
                Apply Portfolio Deduction
                {assetDeductionTotal > 0 && (
                  <span className="badge-space">
                    <Badge style={{ backgroundColor: 'transparent', padding: '0', }}>
                      <span style={{display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'white', }}></span>
                    </Badge>
                  </span>
                )}
              </Button>
              </div>
              <Button variant="secondary" onClick={closeAddAssetModal}>Close</Button>
            </Modal.Footer>
            </Modal>
     
            <Modal show={isAssetModalOpen} onHide={closeAssetModal}>
            <Modal.Header closeButton>
            <Modal.Title>Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor="portfolioName" className="small">Description:</Form.Label>
              <Form.Control name="portfolioName" id="portfolioName" className="form-control border-secondary" type="text" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} placeholder="Name"/>
              {errors.portfolioName && <Alert className="alert alert-danger">{errors.portfolioName}</Alert>}
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="portfolioAmount" className="small">Amount ($,£):</Form.Label>
              <Form.Control name="portfolioAmount" id="portfolioAmount" className="form-control border-secondary" type="number" value={portfolioAmount} onChange={(e) => setPortfolioAmount(e.target.value)} placeholder="Amount"/>
              {errors.portfolioAmount && <Alert className="alert alert-danger">{errors.portfolioAmount}</Alert>}
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="portfolioType" className="small">Fund type:</Form.Label>
                <Form.Select name="portfolioType" id="portfolioType" className="form-control border-secondary" value={portfolioType} onChange={(e) => setPortfolioType(e.target.value)} defaultValue="0">
                  <option value="0">General Account</option>
                  <option value="1">Tax Exempt Savings Account</option>
                  <option value="2">Tax Deferred Pension Account</option>
                </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="portfolioEquity" className="small">% Equity:</Form.Label>
              <Form.Control name="portfolioEquity" id="portfolioEquity" className="form-control border-secondary" type="number" value={portfolioEquity} onChange={(e) => setPortfolioEquity(e.target.value)} placeholder="%"/>
              {errors.portfolioEquity && <Alert className="alert alert-danger">{errors.portfolioEquity}</Alert>}  
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="portfolioBonds" className="small">% Conventional Bonds:</Form.Label>
                <Form.Control name="portfolioBonds" id="portfolioBonds" className="form-control border-secondary" type="number" value={portfolioBonds} onChange={(e) => setPortfolioBonds(e.target.value)} placeholder="%"/>
              {errors.portfolioBonds && <Alert className="alert alert-danger">{errors.portfolioBonds}</Alert>}
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="portfolioIndex" className="small">% Index Bonds:</Form.Label>
                <Form.Control name="portfolioIndex" id="portfolioIndex" className="form-control border-secondary" type="number" value={portfolioIndex} onChange={(e) => setPortfolioIndex(e.target.value)} placeholder="%"/>
              {errors.portfolioIndex && <Alert className="alert alert-danger">{errors.portfolioIndex}</Alert>}
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="portfolioDate" className="small">Last update:</Form.Label>
                <Form.Control name="portfolioDate" id="portfolioDate" className="form-control border-secondary" type="date" value={portfolioDate} onChange={(e) => setPortfolioDate(e.target.value)} placeholder="yyyy-mm-dd"/>
              {errors.portfolioDate && <Alert className="alert alert-danger">{errors.portfolioDate}</Alert>}
            </Form.Group>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handlePortfolioChange }>Enter</Button>
            </Modal.Footer>
            </Modal>



            <Modal show={isAssetDeductionModalOpen} onHide={closeAssetDeductionModal} size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Portfolio Deduction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <>Apply a deduction to aggregate asset portfolio. Enter positive value(s) in relevant box(es).</>
            <Table hover className="striped bordered hover small">
            <thead>
                <tr>
                  <th style={{ width: '25%' }}></th>
                  <th style={{ width: '25%' }}>Equities</th>
                  <th style={{ width: '25%' }}>Conventional Bonds</th>
                  <th style={{ width: '25%' }}>Index Bonds</th>     
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td >General Acc.</td>
                  <td ><Form.Group><Form.Control name="deductionEqGA" id="deductionEqGA" className="form-control border-secondary" type="number" value={deductionEqGA} onChange={(e) => setDeductionEqGA(e.target.value)} placeholder="0"/>{errors.deductionEqGA && <Alert className="alert alert-danger">{errors.deductionEqGA}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionBondGA" id="deductionBondGA" className="form-control border-secondary" type="number" value={deductionBondGA} onChange={(e) => setDeductionBondGA(e.target.value)} placeholder="0"/>{errors.deductionBondGA && <Alert className="alert alert-danger">{errors.deductionBondGA}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionIndexGA" id="deductionIndexGA" className="form-control border-secondary" type="number" value={deductionIndexGA} onChange={(e) => setDeductionIndexGA(e.target.value)} placeholder="0"/>{errors.deductionIndexGA && <Alert className="alert alert-danger">{errors.deductionIndexGA}</Alert>}</Form.Group></td>     
                </tr>
                <tr>
                  <td >Tax Exempt</td>
                  <td ><Form.Group><Form.Control name="deductionEqTE" id="deductionEqTE" className="form-control border-secondary" type="number" value={deductionEqTE} onChange={(e) => setDeductionEqTE(e.target.value)} placeholder="0"/>{errors.deductionEqTE && <Alert className="alert alert-danger">{errors.deductionEqTE}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionBondTE" id="deductionBondTE" className="form-control border-secondary" type="number" value={deductionBondTE} onChange={(e) => setDeductionBondTE(e.target.value)} placeholder="0"/>{errors.deductionBondTE && <Alert className="alert alert-danger">{errors.deductionBondTE}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionIndexTE" id="deductionIndexTE" className="form-control border-secondary" type="number" value={deductionIndexTE} onChange={(e) => setDeductionIndexTE(e.target.value)} placeholder="0"/>{errors.deductionIndexTE && <Alert className="alert alert-danger">{errors.deductionIndexTE}</Alert>}</Form.Group></td>     
                </tr>
                <tr>
                  <td >Tax Deferred</td>
                  <td ><Form.Group><Form.Control name="deductionEqTD" id="deductionEqTD" className="form-control border-secondary" type="number" value={deductionEqTD} onChange={(e) => setDeductionEqTD(e.target.value)} placeholder="0"/>{errors.deductionEqTD && <Alert className="alert alert-danger">{errors.deductionEqTD}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionBondTD" id="deductionBondTD" className="form-control border-secondary" type="number" value={deductionBondTD} onChange={(e) => setDeductionBondTD(e.target.value)} placeholder="0"/>{errors.deductionBondTD && <Alert className="alert alert-danger">{errors.deductionBondTD}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionIndexTD" id="deductionIndexTD" className="form-control border-secondary" type="number" value={deductionIndexTD} onChange={(e) => setDeductionIndexTD(e.target.value)} placeholder="0"/>{errors.deductionIndexTD && <Alert className="alert alert-danger">{errors.deductionIndexTD}</Alert>}</Form.Group></td>     
                </tr>
            </tbody>
            </Table>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handleAssetDeductionChange }>Enter</Button>
            </Modal.Footer>
            </Modal>

            <div className="mt-2"></div>
            <div className="my-2" />

            {/* State Modal */}
            <Modal show={isAddStateModalOpen} onHide={closeAddStateModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>State Pension</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              <div className="mt-4" />
              <Row>
              {presetObject.state_pension
                .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                .map((item) => (
                  <Col key={item.originalIndex} md={3} className="mb-4">
                    <Card bg="dark" text="white" className="border-light">
                      <Card.Body>
                        <Card.Title className="h6">{item.name}</Card.Title>
                        <hr className="hr-line" />
                        <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>
                        <Card.Text className="small tight-spacing">Annual amount ($,£): {item.amount}</Card.Text>
                        <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                        <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                        <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                        <Button variant="danger" onClick={() => handleStatePensionDeleteItem(item.originalIndex)}>Delete</Button>
                        <span> </span>
                        <Button variant="info" onClick={() => handleStatePensionModityItem(item.originalIndex)}>Modify</Button>                      
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openStateModal}>Add State Pension</Button>
                <Button variant="secondary" onClick={closeAddStateModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isStateModalOpen} onHide={closeStateModal}>
            <Modal.Header closeButton>
            <Modal.Title>State Pension</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group as={Col}>
                <Form.Label htmlFor="stateName" className="small">Description:</Form.Label>
                <Form.Control name="stateName" id="stateName" className="form-control border-secondary" type="text" value={stateName} onChange={(e) => setStateName(e.target.value)} placeholder="Name" />                
                {errors.stateName && <Alert className="alert alert-danger">{errors.stateName}</Alert>}
              </Form.Group>
            <Form.Group as={Col}>
                <Form.Label htmlFor="stateYear" className="small">Year (age):</Form.Label>
                <Form.Control name="stateYear" id="stateYear" className="form-control border-secondary" type="number" value={stateYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setStateYear(e.target.value)}}} placeholder="Year" min="1" />               
                {errors.stateYear && <Alert className="alert alert-danger">{errors.stateYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="stateAmount" className="small">Amount (current):</Form.Label>
                <Form.Control name="stateAmount" id="stateAmount" className="form-control border-secondary" type="number" value={stateAmount} onChange={(e) => setStateAmount(e.target.value)} placeholder="Amount" min="1" />                
                {errors.stateAmount && <Alert className="alert alert-danger">{errors.stateAmount}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="stateIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select name="stateIndex" id="stateIndex" className="form-control border-secondary" value={stateIndex} onChange={(e) => setStateIndex(e.target.value)} defaultValue="1">     
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="stateIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control name="stateIncrease" id="stateIncrease" className="form-control border-secondary" type="number" value={stateIncrease} onChange={(e) => setStateIncrease(e.target.value)} placeholder="%" />       
                {errors.stateIncrease && <Alert className="alert alert-danger">{errors.stateIncrease}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="stateDate" className="small">Last update:</Form.Label>
                <Form.Control name="stateData" id="stateDate" className="form-control border-secondary" type="date" value={stateDate} onChange={(e) => setStateDate(e.target.value)} placeholder="yyyy-mm-dd" />       
                {errors.stateDate && <Alert className="alert alert-danger">{errors.stateDate}</Alert>}
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handleStatePensionChange }>Enter</Button>
            </Modal.Footer>
            </Modal>

            {/* Occupational Modal */}
            <Modal show={isAddOccupationModalOpen} onHide={closeAddOccupationModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Occupational Pension</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              <div className="mt-4" />
              <Row>
            {presetObject.occupational_pension
              .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
              .map((item) => (
                <Col key={item.originalIndex} md={3} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>
                      <Card.Text className="small tight-spacing">Annual amount ($,£): {item.amount}</Card.Text>
                      <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                      <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                      <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                      <Button variant="danger" onClick={() => handleOccupationalPensionDeleteItem(item.originalIndex)}>Delete</Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handleOccupationalPensionModityItem(item.originalIndex)}>Modify</Button>   
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openOccupationalModal}>Add Occupational Pension</Button>
                <Button variant="secondary" onClick={closeAddOccupationModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isOccupationalModalOpen} onHide={closeOccupationalModal}>
            <Modal.Header closeButton>
            <Modal.Title>Occupational Pension</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalName" className="small">Description:</Form.Label>
                <Form.Control name="occupationalName" id="occupationalName" className="form-control border-secondary" type="text" value={occupationalName} onChange={(e) => setOccupationalName(e.target.value)} placeholder="Name" />
                {errors.occupationalName && <Alert className="alert alert-danger">{errors.occupationalName}</Alert>}
              </Form.Group>
            <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalYear" className="small">Year (age):</Form.Label>
                <Form.Control name="occupationalYear" id="occupationalYear" className="form-control border-secondary" type="number" value={occupationalYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setOccupationalYear(e.target.value)}}} placeholder="Year" min="1" />     
                {errors.occupationalYear && <Alert className="alert alert-danger">{errors.occupationalYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalAmount" className="small">Amount (current):</Form.Label>
                <Form.Control name="occupationalAmount" id="occupationalAmount" className="form-control border-secondary" type="number" value={occupationalAmount} onChange={(e) => setOccupationalAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.occupationalAmount && <Alert className="alert alert-danger">{errors.occupationalAmount}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select name="occupationalIndex" id="occupationalIndex" className="form-control border-secondary" value={occupationalIndex} onChange={(e) => setOccupationalIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control name="occupationalIndex" id="occupationalIndex" className="form-control border-secondary" type="number" value={occupationalIncrease} onChange={(e) => setOccupationalIncrease(e.target.value)} placeholder="%" />
                {errors.occupationalIncrease && <Alert className="alert alert-danger">{errors.occupationalIncrease}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalDate" className="small">Last update:</Form.Label>
                <Form.Control name="occupationalDate" id="occupationalDate" className="form-control border-secondary" type="date" value={occupationalDate} onChange={(e) => setOccupationalDate(e.target.value)} placeholder="yyyy-mm-dd" />
                {errors.occupationalDate && <Alert className="alert alert-danger">{errors.occupationalDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
            <Button variant="success" onClick={handleOccupationalPensionChange }>Enter</Button>
            </Modal.Footer>
            </Modal>

            <hr className="my-2" />           
 
            <div className="d-flex justify-content-between align-items-start">
            <h6>Future retirement saving / additions to asset portfolio:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Add recurring contributions (e.g. further (defined contribution) pension contributions up to retirement, net rental income from property assets not included in asset portfolio, etc) or one-off contributions (e.g. property sale) that will add to the portfolio value or offset expenditure during the simulation.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1rem' }} /></span>
            </OverlayTrigger>
            </div>

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddContributionModal}>Add recurring contributions
              <span className="badge-space"><Badge bg="info">{presetObject.contribution_inflows.length ? presetObject.contribution_inflows.length : null}</Badge></span>
              </Button>
            </div>
            <div className="my-2" />
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddExtraordinaryModal}>Add one-off contributions
              <span className="badge-space"><Badge bg="info">{presetObject.extraordinary_inflows.length ? presetObject.extraordinary_inflows.length : null}</Badge></span>
              </Button>
            </div>

            {/* Contribution Modal */}
            <Modal show={isAddContributionModalOpen} onHide={closeAddContributionModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Recurring Contributions</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
            <div className="mt-4" />

            <Row>
              {presetObject.contribution_inflows
                .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                .map((item) => (
                  <Col key={item.originalIndex} md={3} className="mb-4">
                    <Card bg="dark" text="white" className="border-light">
                      <Card.Body>
                        <Card.Title className="h6">{item.name}</Card.Title>
                        <hr className="hr-line" />
                        <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>
                        <Card.Text className="small tight-spacing">End year (age): {item.yearend}</Card.Text>                      
                        <Card.Text className="small tight-spacing">Annual amount ($,£): {item.amount}</Card.Text>
                        <Card.Text className="small tight-spacing">Fund type: {item.type === '0' ? 'General Account' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}</Card.Text>
                        <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                        <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                        <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                        <Button variant="danger" onClick={() => handleContributionDeleteItem(item.originalIndex)}>Delete</Button>
                        <span> </span>
                        <Button variant="info" onClick={() => handleContributionModityItem(item.originalIndex)}>Modify</Button>   
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>

            </Modal.Body>
              {/* <Modal.Footer className="bg-dark text-light border-light">
                <Button className="btn btn-success" onClick={openContributionModal}>Add</Button>
                <Button variant="secondary" onClick={closeAddContributionModal}>Close</Button>
              </Modal.Footer> */}
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openContributionModal}>Add Recurring Contribution</Button>
                <Button variant="secondary" onClick={closeAddContributionModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isContributionModalOpen} onHide={closeContributionModal}>
            <Modal.Header closeButton>
            <Modal.Title>Contribution</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group as={Col}>
                <Form.Label htmlFor="contributionName" className="small">Description:</Form.Label>
                <Form.Control name="contributionName" id="contributionName" className="form-control border-secondary" type="text" value={contributionName} onChange={(e) => setContributionName(e.target.value)} placeholder="Name" />
              {errors.contributionName && <Alert className="alert alert-danger">{errors.contributionName}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="contributionYear" className="small">Start year (age):</Form.Label>
                <Form.Control name="contributionYear" id="contributionYear" className="form-control border-secondary" type="number" value={contributionYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setContributionYear(e.target.value)}}} placeholder="AgeStart" min="1" />
                {errors.contributionYear && <Alert className="alert alert-danger">{errors.contributionYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="contributionYearEnd" className="small">End year (age):</Form.Label>
                <Form.Control name="contributionYearEnd" id="contributionYearEnd" className="form-control border-secondary" type="number" value={contributionYearEnd} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setContributionYearEnd(e.target.value)}}} placeholder="AgeEnd" min="1" />
                {errors.contributionYearEnd && <Alert className="alert alert-danger">{errors.contributionYearEnd}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="contributionAmount" className="small">Amount (current):</Form.Label>
                <Form.Control name="contributionAmount" id="contributionAmount" className="form-control border-secondary" type="number" value={contributionAmount} onChange={(e) => setContributionAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.contributionAmount && <Alert className="alert alert-danger">{errors.contributionAmount}</Alert>}
                </Form.Group>

                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionType" className="small">Fund type:</Form.Label>
                <Form.Select name="contributionType" id="contributionType" className="form-control border-secondary" value={contributionType} onChange={(e) => setContributionType(e.target.value)} defaultValue="0">
                  <option value="0">General Account</option>
                  <option value="1">Tax Exempt Account</option>
                  <option value="2">Tax Deferred Account</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionIndex" className="small">Index (Y/N):</Form.Label>
                <Form.Select name="contributionIndex" id="contributionIndex" className="form-control border-secondary" value={contributionIndex} onChange={(e) => setContributionIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>

                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control name="contributionIncrease" id="contributionIncrease" className="form-control border-secondary" type="number" value={contributionIncrease} onChange={(e) => setContributionIncrease(e.target.value)} placeholder="%" />
                {errors.contributionIncrease && <Alert className="alert alert-danger">{errors.contributionIncrease}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionDate" className="small">Last update:</Form.Label>
                <Form.Control name="contributionDate" id="contributionDate" className="form-control border-secondary" type="date" value={contributionDate} onChange={(e) => setContributionDate(e.target.value)} placeholder="yyyy-mm-dd" />
                {errors.contributionDate && <Alert className="alert alert-danger">{errors.contributionDate}</Alert>}
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleContributionChange }>Enter</Button>
                </Modal.Footer>
                </Modal>
            <div className="my-2" />



            {/* Extraordinary Modal */}
            <Modal show={isAddExtraordinaryModalOpen} onHide={closeAddExtraordinaryModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>One-Off Contributions</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              <div className="mt-4" />
              <Row>
                {presetObject.extraordinary_inflows
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>                   
                          <Card.Text className="small tight-spacing">Annual amount ($,£): {item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Fund type: {item.type === '0' ? 'General Account' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                          <Button variant="danger" onClick={() => handleExtraDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleExtraModityItem(item.originalIndex)}>Modify</Button>                      
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>

              </Modal.Body>
              {/* <Modal.Footer className="bg-dark text-light border-light">
                <Button className="btn btn-success" onClick={openExtraordinaryModal}>Add</Button>
                <Button variant="secondary" onClick={closeAddExtraordinaryModal}>Close</Button>
              </Modal.Footer> */}
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openExtraordinaryModal}>Add One-Off Contribution</Button>
                <Button variant="secondary" onClick={closeAddExtraordinaryModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isExtraordinaryModalOpen} onHide={closeExtraordinaryModal}>
            <Modal.Header closeButton>
            <Modal.Title>Extraordinary inflow</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="extraName" className="small">Description:</Form.Label>
                <Form.Control name="extraName" id="extraName" className="form-control border-secondary" type="text" value={extraName} onChange={(e) => setExtraName(e.target.value)} placeholder="Name" />
                {errors.extraName && <Alert className="alert alert-danger">{errors.extraName}</Alert>}
              </Form.Group>  
            <Form.Group>
                <Form.Label htmlFor="extraYear" className="small">Year (age):</Form.Label>
                <Form.Control name="extraYear" id="extraYear" className="form-control border-secondary" type="number" value={extraYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setExtraYear(e.target.value)}}} placeholder="Year" min="1" />
              {errors.extraYear && <Alert className="alert alert-danger">{errors.extraYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="extraAmount" className="small">Amount (current):</Form.Label>
                <Form.Control name="extraAmount" id="extraAmount" className="form-control border-secondary" type="number" value={extraAmount} onChange={(e) => setExtraAmount(e.target.value)} placeholder="Amount" min="1" />
              {errors.extraAmount && <Alert className="alert alert-danger">{errors.extraAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraType" className="small">Fund type:</Form.Label>
                <Form.Select name="extraType" id="extraType" className="form-control border-secondary" value={extraType} onChange={(e) => setExtraType(e.target.value)} defaultValue="1">
                  <option value="0">General Account</option>
                  <option value="1">Tax Exempt Account</option>
                  <option value="2">Tax Deferred Account</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select name="extraIndex" id="extraIndex" className="form-control border-secondary" value={extraIndex} onChange={(e) => setExtraIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control name="extraIncrease" id="extraIncrease" className="form-control border-secondary" type="number" value={extraIncrease} onChange={(e) => setExtraIncrease(e.target.value)} placeholder="%" />      
                {errors.extraIncrease && <Alert className="alert alert-danger">{errors.extraIncrease}</Alert>}                
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraDate" className="small">Last update:</Form.Label>
                <Form.Control name="extraDate" id="extraDate" className="form-control border-secondary" type="date" value={extraDate} onChange={(e) => setExtraDate(e.target.value)} placeholder="yyyy-mm-dd" />      
                {errors.extraDate && <Alert className="alert alert-danger">{errors.extraDate}</Alert>}                
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleExtraInflowChange }>Enter</Button>
                </Modal.Footer>
                </Modal>

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Retirement withdrawal approach and planned expenditure:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Select a withdrawal approach that either covers all planned expenditure or targets a percentage rate of portfolio withdrawal with option of inputting minimum planned expenditure to set a floor for the annual withdrawal amount.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`} className={"custom-tooltip"}>- Fixed: fixed withdrawal taken each year irrespective of market performance.<br />- Fixed with bonus: as fixed but additional 'bonus' withdrawal if portfolio value above safe funding level.<br />- Fixed percentage withdrawal: withdrawal level calculated each year as a % of portfolio value with option to add minimum withdrawal level<br />- Yale Endownment: withdrawal = x * previous withdrawal + (1 - x) * % portfolio value<br />- Vanguard Dynamic Spending: % portfolio value with cap / floor on max real-term change from previous withdrawal<br />- Bogleheads Variable Percentage Withdrawal: % portfolio value taken from look up table adjusted for age and asset mix</Tooltip>}>           
            <Form.Label htmlFor="dynamic_option" className="fw-light small">Select portfolio withdrawal approach:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="dynamic_option" id="dynamic_option" className="form-control bg-secondary border-secondary text-light" onChange={(e) => handleSelectChange('dynamic_option', e.target.value)} defaultValue="constant">
              <option value="constant" >Fixed amount</option>
              <option value="constantbonus">Fixed amount with bonus</option>
              {/* <option value="constantflex" >Flex $ (dynamic SWR)</option> */}
              <option value="proportional" >Fixed percentage withdrawal</option>
              <option value="yale" >Yale endownment rule</option>
              <option value="vanguard" >Vanguard dynamic spending rule</option>
              <option value="vpw" >Bogleheads variable percentage withdrawal</option>
		        </Form.Select>
            {presetObject.dynamic_option === 'constant' || presetObject.dynamic_option === 'constantbonus' ? (
              <>
              </>
              ) : presetObject.dynamic_option === 'yale' ? (
                <>
              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Annual draw. rate (as % of port.):" formname="target_withdrawal_percent" type="number" overlaytext="Annual income withdrawal rate (as % of portfolio value each year through simulation) (net of tax)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
  
              <Form.Group as={Col}>
              <FormTemplate formlabel="Weight. of previous year's draw. (%):" formname="yale_weighting" type="number" overlaytext="Income withdrawal = weighting x previous_year_withdrawal + (1 - weighting) * (withdrawal rate * portfolio value)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} /> 
              </Form.Group>
              </Row>
              </>
              ) : presetObject.dynamic_option === 'vanguard' ? (
              <>
              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Annual draw. (as % of port.):" formname="target_withdrawal_percent" type="number" overlaytext="Annual income withdrawal rate (as % of portfolio value each year through simulation) (net of tax)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              {/* </Row>
              <Row> */}
              <Form.Group as={Col}>

              <FormTemplate formlabel="Maximum annual real-term reduction (%):" formname="vanguard_decrease_floor" type="number" overlaytext="Maximum annual real-term reduction (%)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              <Form.Group as={Col}>

              <FormTemplate formlabel="Maximum annual real-term increase (%):" formname="vanguard_increase_ceiling" type="number" overlaytext="Maximum annual real-term increase (%)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
                
              </Form.Group>
              </Row>
              </>
              ) : presetObject.dynamic_option === 'vpw' ? (<></>)
              : (
              <>
              <Row>
              {/* <Form.Group as={Col}> */}
              <Form.Group>
              <FormTemplate formlabel="Annual withdrawal (as % of portfolio):" formname="target_withdrawal_percent" type="number" overlaytext="Annual total income withdrawal (as % of portfolio value each year through simulation) (net of tax)." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>

              {/* <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Sets whether other income (e.g. state pension, occupational pension, purchased annuity) is included in calculating withdrawal (total income) amount as a % portfolio value.</Tooltip>}>           
                <Form.Label htmlFor="net_other_income" className="fw-light">Include other income in withdrawal calc:</Form.Label>
                </OverlayTrigger>
                <Form.Select name="net_other_income" id="net_other_income" className="form-control bg-secondary border-secondary text-light" onChange={(e) => handleSelectChange('net_other_income', e.target.value)} defaultValue="0">
                <option value="0" >Do not include</option>
                <option value="1" >Include</option>
                </Form.Select>
              </Form.Group> */}

              </Row>
              </>  
              )}

            <div className="mt-2"></div>

            {presetObject.dynamic_option !== 'vpw' ? 
            <>
            <div className="my-2" />

            <div className="my-2" />
            {/* <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddIncomeRequirementsModal}>Add income requirements / planned expenditure
              <span className="badge-space"><Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : null}</Badge></span>
              </Button>
            </div> */}

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} onClick={openAddIncomeRequirementsModal}>
              {presetObject.dynamic_option === 'proportional' || presetObject.dynamic_option === 'yale' || presetObject.dynamic_option === 'vanguard' ? (<span style={{ flexGrow: 1, textAlign: 'left' }}>Add minimum planned expenditure</span>) : (<span style={{ flexGrow: 1, textAlign: 'left' }}>Add planned expenditure</span>)}     
                <span className="badge-space"><Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : null}</Badge></span>
              </Button>
            </div>

            {/* <div className="accordion-item">
            <div className="accordion-header" style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.75rem 1.25rem', backgroundColor: '#6c757d', color: '#ffffff', border: '1px solid #ced4da', borderRadius: '0.25rem'}} onClick={openAddIncomeRequirementsModal}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Option to add one-off contributions (e.g. property sale) that will add to the portfolio value at a specified point during the simulation.</Tooltip>}>
                {presetObject.dynamic_option === 'constant' ? <span className="text-light">Add income requirements / planned expenditure</span> : presetObject.dynamic_option === 'constantbonus' ? <span className="text-light">Add 'fixed' income requirements / planned expenditure</span> : <span className="text-light">Add 'minimum' income requirements / planned expenditure</span>}
              </OverlayTrigger>
                <span className="badge-space"><Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : null}</Badge>
              </span>
            </div>
            </div> */}

            {/* IncomeRequirements Modal */}
            <Modal show={isAddIncomeRequirementsModalOpen} onHide={closeAddIncomeRequirementsModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Planned Expenditure</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              
              <div className="mt-4" />

              <Row>
                {presetObject.income_requirements
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>     
                          <Card.Text className="small tight-spacing">End year (age): {item.yearend}</Card.Text>                                     
                          <Card.Text className="small tight-spacing" >Annual amount ($,£): {item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' || item.index === '2' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. to index up to start (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. to index from start (%): {item.increase2}</Card.Text>   
                          <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>                   
                          <Button variant="danger" onClick={() => handleIncomeDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleIncomeModityItem(item.originalIndex)}>Modify</Button>   
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>

              </Modal.Body>
              {/* <Modal.Footer className="bg-dark text-light border-light">
                <Button className="btn btn-success" onClick={openIncomeModal}>Add Income Requirement</Button>
                <Button variant="secondary" onClick={closeAddIncomeRequirementsModal}>Close</Button>
              </Modal.Footer> */}
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openIncomeModal}>Add Expenditure</Button>
                <Button variant="secondary" onClick={closeAddIncomeRequirementsModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isIncomeModalOpen} onHide={closeIncomeModal}>
            <Modal.Header closeButton>
            <Modal.Title>Planned Expenditure</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="incomeName" className="small">Description:</Form.Label>
                <Form.Control name="incomeName" id="incomeName" className="form-control border-secondary" type="text" value={incomeName} onChange={(e) => setIncomeName(e.target.value)} placeholder="Name" />
            {errors.incomeName && <Alert className="alert alert-danger">{errors.incomeName}</Alert>}
            </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="incomeYear" className="small">Start age:</Form.Label>
                <Form.Control name="incomeYear" id="incomeYear" className="form-control border-secondary" type="number" value={incomeYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setIncomeYear(e.target.value)}}} placeholder="AgeStart" min="1" />
              </Form.Group>
              {errors.incomeYear && <Alert className="alert alert-danger">{errors.incomeYear}</Alert>}
              <Form.Group>
                <Form.Label htmlFor="incomeYearEnd" className="small">End age:</Form.Label>
                <Form.Control name="incomeYearEnd" id="incomeYearEnd" className="form-control border-secondary" type="number" value={incomeYearEnd} onChange={(e) => { if (/^\d*$/.test(e.target.value)) setIncomeYearEnd(e.target.value)}} placeholder="AgeEnd" min="1" />
              </Form.Group>
              {errors.incomeYearEnd && <Alert className="alert alert-danger">{errors.incomeYearEnd}</Alert>}
              <Form.Group>
                <Form.Label htmlFor="incomeAmount" className="small">Amount (current):</Form.Label>
                <Form.Control name="incomeAmount" id="incomeAmount" className="form-control border-secondary" type="number" value={incomeAmount} onChange={(e) => setIncomeAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.incomeAmount && <Alert className="alert alert-danger">{errors.incomeAmount}</Alert>}
                </Form.Group>

                <Form.Group>
                <Form.Label htmlFor="incomeIndex" className="small">Index (Y/N):</Form.Label>
                <Form.Select name="incomeIndex" id="incomeIndex" className="form-control border-secondary" value={incomeIndex} onChange={(e) => setIncomeIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="2">Indexed (differential rates)</option>
                  <option value="0">Not Indexed</option>
                  <option value="3">Not Indexed (differential rates)</option>
                </Form.Select>
                </Form.Group>

                {incomeIndex === '0' || incomeIndex === '1' ? 
                <>
                <Form.Group>
                <Form.Label htmlFor="incomeIncrease" className="small">Inc. over index up to start age (%):</Form.Label>
                <Form.Control name="incomeIncrease" id="incomeIncrease" className="form-control border-secondary" type="number" value={incomeIncrease} onChange={(e) => setIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                </Form.Group>
                </>
                :
                <>
                <Form.Group>
                <Form.Label htmlFor="incomeIncrease" className="small">Inc. to index up to income start (%):</Form.Label>
                <Form.Control name="incomeIncrease" id="incomeIncrease" className="form-control border-secondary" type="number" value={incomeIncrease} onChange={(e) => setIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>                  
                <Form.Group>
                <Form.Label htmlFor="incomeIncrease2" className="small">Inc. to index from income start (%):</Form.Label>
                <Form.Control name="incomeIncrease2" id="incomeIncrease2" className="form-control border-secondary" type="number" value={incomeIncrease2} onChange={(e) => setIncomeIncrease2(e.target.value)} placeholder="%" />
                {errors.incomeIncrease2 && <Alert className="alert alert-danger">{errors.incomeIncrease2}</Alert>}
                </Form.Group>
                </>}
                <Form.Group>
                <Form.Label htmlFor="incomeDate" className="small">Last update:</Form.Label>
                <Form.Control name="incomeDate" id="incomeDate" className="form-control border-secondary" type="date" value={incomeDate} onChange={(e) => setIncomeDate(e.target.value)} placeholder="yyyy-mm-dd" />
                {errors.incomeDate && <Alert className="alert alert-danger">{errors.incomeDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleIncomeChange }>Enter</Button>
                </Modal.Footer>
                </Modal>

            </> : <></>}  

            <div className="my-2" />
            {presetObject.dynamic_option === 'constantflex' || presetObject.dynamic_option === 'constantbonus' ? 

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddFlexIncomeRequirementsModal}>Add bonus expenditure
              <span className="badge-space"><Badge bg="info">{presetObject.flex_income_requirements.length ? presetObject.flex_income_requirements.length : null}</Badge></span>
              </Button>
            </div>

            // <div className="accordion-item">
            // <div className="accordion-header" style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.75rem 1.25rem', backgroundColor: '#6c757d', color: '#ffffff', border: '1px solid #ced4da', borderRadius: '0.25rem'}} onClick={openAddFlexIncomeRequirementsModal}>
            //   <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add 'bonus' income targets.</Tooltip>}>
            //     <span className="text-light">Add 'bonus' income targets</span>
            //   </OverlayTrigger>
            //     <span className="badge-space"><Badge bg="info">{presetObject.flex_income_requirements.length ? presetObject.flex_income_requirements.length : null}</Badge>
            //   </span>
            // </div>
            // </div>
            : <></>}

            {/* FlexIncomeRequirements Modal */}
            <Modal show={isAddFlexIncomeRequirementsModalOpen} onHide={closeAddFlexIncomeRequirementsModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Bonus Expenditure</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

            <div className="mt-4" />
            <Row>
                {presetObject.flex_income_requirements
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>     
                          <Card.Text className="small tight-spacing">End year (age): {item.yearend}</Card.Text>                                     
                          <Card.Text className="small tight-spacing">Annual amount ($,£): {item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                          <Button variant="danger" onClick={() => handleFlexIncomeDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleFlexIncomeModityItem(item.originalIndex)}>Modify</Button>   
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>

              </Modal.Body>
              {/* <Modal.Footer className="bg-dark text-light border-light">
                <Button className="btn btn-success" onClick={openBonusModal}>Add</Button>
                <Button variant="secondary" onClick={closeAddFlexIncomeRequirementsModal}>Close</Button>
              </Modal.Footer> */}
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openBonusModal}>Add Bonus Expenditure</Button>
                <Button variant="secondary" onClick={closeAddFlexIncomeRequirementsModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isBonusModalOpen} onHide={closeBonusModal}>
            <Modal.Header closeButton>
            <Modal.Title>Bonus Expenditure</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="flexIncomeName" className="small">Description:</Form.Label>
                <Form.Control name="flexIncomeName" id="flexIncomeName" className="form-control border-secondary" type="text" value={flexIncomeName} onChange={(e) => setFlexIncomeName(e.target.value)} placeholder="Name" />
              {errors.flexIncomeName && <Alert className="alert alert-danger">{errors.flexIncomeName}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="flexIncomeYear" className="small">Start age:</Form.Label>
                <Form.Control name="flexIncomeYear" id="flexIncomeYear" className="form-control border-secondary" type="number" value={flexIncomeYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setFlexIncomeYear(e.target.value)}}} placeholder="AgeStart" min="1" />
              {errors.flexIncomeYear && <Alert className="alert alert-danger">{errors.flexIncomeYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="flexIncomeYearEnd" className="small">End age:</Form.Label>
                <Form.Control name="flexIncomeYearEnd" id="flexIncomeYearEnd" className="form-control border-secondary" type="number" value={flexIncomeYearEnd} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setFlexIncomeYearEnd(e.target.value)}}} placeholder="AgeEnd" min="1" />
              {errors.flexIncomeYearEnd && <Alert className="alert alert-danger">{errors.flexIncomeYearEnd}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="flexIncomeAmount" className="small">Amount (current):</Form.Label>
                <Form.Control name="flexIncomeAmount" id="flexIncomeAmount" className="form-control border-secondary" type="number" value={flexIncomeAmount} onChange={(e) => setFlexIncomeAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.flexIncomeAmount && <Alert className="alert alert-danger">{errors.flexIncomeAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="flexIncomeIndex" className="small">Index (Y/N):</Form.Label>
                <Form.Select name="flexIncomeIndex" id="flexIncomeIndex" className="form-control border-secondary" value={flexIncomeIndex} onChange={(e) => setFlexIncomeIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="flexIncomeIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control name="flexIncomeIncrease" id="flexIncomeIncrease" className="form-control border-secondary" type="number" value={flexIncomeIncrease} onChange={(e) => setFlexIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.flexIncomeIncrease && <Alert className="alert alert-danger">{errors.flexIncomeIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="flexIncomeDate" className="small">Last update:</Form.Label>
                <Form.Control name="flexIncomeDate" id="flexIncomeDate" className="form-control border-secondary" type="date" value={flexIncomeDate} onChange={(e) => setFlexIncomeDate(e.target.value)} placeholder="yyyy-mm-dd" />
                {errors.flexIncomeDate && <Alert className="alert alert-danger">{errors.flexIncomeDate}</Alert>}
                </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleFlexIncomeChange }>Enter</Button>
                </Modal.Footer>
                </Modal>

            <hr className="my-2" />           
            <div className="d-flex justify-content-between align-items-start">
            <h6>Portfolio asset mix:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">The asset mix for duration of the simulation; the simulation calculator automatically rebalances the asset mix each year.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>

            <Form.Group as={Col}>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter asset mix source.</Tooltip>}>           
            <Form.Label htmlFor="asset_mix_source" className="fw-light small">Asset mix source:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="asset_mix_source" id="asset_mix_source" className="form-control bg-secondary border-secondary text-light" onChange={(e) => handleSelectChange('asset_mix_source', e.target.value)} value={presetObject.asset_mix_source}>
              <option value="0" >Use asset mix in asset portfolio</option>
              <option value="1" >Enter asset mix for simulation</option>
            </Form.Select>
            </Form.Group>

            {presetObject.asset_mix_source === "0" ? <></> : 
            <>
            <div className="my-2" />
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter portfolio asset mix at beginning of simulation (simulation rebalances asset mix each year)</Tooltip>}>           
            <span></span>
            </OverlayTrigger>
            {/* {(presetObject.data_option === 'forward' || presetObject.data_option === 'forwardUSD' || presetObject.data_option === 'forwardUSDglobal') ? ( */}
            {(presetObject.data_direction === 'forward') ? (
              <div>
            <Row className="d-flex align-items-end">
            <Form.Group as={Col}>
            <FormTemplate formlabel="Equity (of 100%):" formname="asset_mix_equity" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />            
            </Form.Group>
            <Form.Group as={Col}>
            <FormTemplate formlabel="Bond (conventional) (of 100%):" formname="asset_mix_bond" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            </Form.Group>
            <Form.Group as={Col}>
            <FormTemplate formlabel="Bond (index) (of 100%):" formname="asset_mix_index_bond" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />              
            </Form.Group>
            </Row> 
            </div>
            ) : (
              <div>
              <Row>
              <Form.Group as={Col}>
              <FormTemplate formlabel="Equity:" formname="asset_mix_equity" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              <Form.Group as={Col}>
              <FormTemplate formlabel="Bond (conventional):" formname="asset_mix_bond" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              </Row>
              </div>
            )
            }
            </>}

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Optional annuitisation:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Option to simulate purchasing, at a future point in the simulation, a lifetime annuity to cover all or part of planned expenditure not covered by other pensions.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddAnnuityModal}>Purchase annuity into portfolio
              <span className="badge-space"><Badge bg="info">{presetObject.annuity_pension.length ? presetObject.annuity_pension.length : null}</Badge></span>
              </Button>
            </div>

            {/* <div className="accordion-item">
            <div className="accordion-header" style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.75rem 1.25rem', backgroundColor: '#6c757d', color: '#ffffff', border: '1px solid #ced4da', borderRadius: '0.25rem'}} onClick={openAddAnnuityModal}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Purchase annuity into portfolio that will provide a lifetime annual income.</Tooltip>}>
                <span className="text-light">Purchase annuity into portfolio</span>
              </OverlayTrigger>
                <span className="badge-space"><Badge bg="info">{presetObject.annuity_pension.length ? presetObject.annuity_pension.length : null}</Badge>
              </span>
            </div>
            </div> */}

            {/* Annuity Modal */}
            <Modal show={isAddAnnuityModalOpen} onHide={closeAddAnnuityModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Purchase Annuity Into Portfolio</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

              <div className="mt-4" />
              <Row>
                {presetObject.annuity_pension
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>
                          <Card.Text className="small tight-spacing">Annual amount ($,£): {item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Price (% notional): {item.price}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Last Update: {item.last_update_date}</Card.Text>
                          <Button variant="danger" onClick={() => handleAnnuityPensionDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleAnnuityPensionModityItem(item.originalIndex)}>Modify</Button>                      
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>


              </Modal.Body>
              {/* <Modal.Footer className="bg-dark text-light border-light">
                <Button className="btn btn-success" onClick={openAnnuityModal}>Add Annuity</Button>
                <Button variant="secondary" onClick={closeAddAnnuityModal}>Close</Button>
              </Modal.Footer> */}
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openAnnuityModal}>Add Annuity</Button>
                <Button variant="secondary" onClick={closeAddAnnuityModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isAnnuityModalOpen} onHide={closeAnnuityModal}>
            <Modal.Header closeButton>
            <Modal.Title>Annuity</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="annuityName" className="small">Description:</Form.Label>
                <Form.Control name="annuityName" id="annuityName" className="form-control border-secondary" type="text" value={annuityName} onChange={(e) => setAnnuityName(e.target.value)} placeholder="Name" />
              {errors.annuityName && <Alert className="alert alert-danger">{errors.annuityName}</Alert>}
              </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="annuityYear" className="small">Start year (age):</Form.Label>
                <Form.Control name="annuityYear" id="annuityYear" className="form-control border-secondary" type="number" value={annuityYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setAnnuityYear(e.target.value)}}} placeholder="Year" min="1" />
              {errors.annuityYear && <Alert className="alert alert-danger">{errors.annuityYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annuity annual income starting in year annuity is purchased.</Tooltip>}>           
                <Form.Label htmlFor="annuityAmount" className="small">Annual income (purchase year):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="annuityAmount" id="annuityAmount" className="form-control border-secondary" type="number" value={annuityAmount} onChange={(e) => setAnnuityAmount(e.target.value)} placeholder="Income" min="1" />
                {errors.annuityAmount && <Alert className="alert alert-danger">{errors.annuityAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter (year 1) annuity income as percentage notional purchase cost.</Tooltip>}>           
                <Form.Label htmlFor="annuityPrice" className="small">Price (income as % notional cost):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="annuityPrice" id="annuityPrice" className="form-control border-secondary" type="number" value={annuityPrice} onChange={(e) => setAnnuityPrice(e.target.value)} placeholder="Price" />
                {errors.annuityPrice && <Alert className="alert alert-danger">{errors.annuityPrice}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="annuityIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select name="annuityIndex" id="annuityIndex" className="form-control border-secondary" value={annuityIndex} onChange={(e) => setAnnuityIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="annuityIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control name="annuityIndex" id="annuityIndex" className="form-control border-secondary" type="number" value={annuityIncrease} onChange={(e) => setAnnuityIncrease(e.target.value)} placeholder="%" />
                {errors.annuityIncrease && <Alert className="alert alert-danger">{errors.annuityIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="annuityDate" className="small">Last update:</Form.Label>
                <Form.Control name="annuityDate" id="annuityDate" className="form-control border-secondary" type="date" value={annuityDate} onChange={(e) => setAnnuityDate(e.target.value)} placeholder="yyyy-mm-dd" />
                {errors.annuityDate && <Alert className="alert alert-danger">{errors.annuityDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleAnnuityPensionChange }>Enter</Button>
                </Modal.Footer>
                </Modal>

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Taxes, fees and data settings:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">- Choose whether to use USD (for US investors) or GBP (for UK investors) denominated dataset. <br />- Set taxes that may be deducted from returns for different fund types. Simulation shows results net of tax.<br />- Set portfolio / management fees that may be deducted from returns.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>


            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openDataChoiceModal}>Back-testing data options
              </Button>
            </div>

            <div className="my-2" />

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openTaxesModal}>Adjust taxes
              </Button>
            </div>
            <div className="my-2" />
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openSettingsModal}>Fees and other simulation settings
              </Button>
            </div>
            <div className="my-2" />
 

            {/* <div className="accordion-item">
            <div className="accordion-header" style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.75rem 1.25rem', backgroundColor: '#6c757d', color: '#ffffff', border: '1px solid #ced4da', borderRadius: '0.25rem'}} onClick={openSettingsModal}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Adjusted settings (taxes, fees, etc).</Tooltip>}>
                <span className="text-light">Adjust</span>
              </OverlayTrigger>
            </div>
            </div> */}

            {/* Taxes Modal */}
            <Modal show={isTaxesModalOpen} onHide={closeTaxesModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Tax settings</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                
              <FormTemplateLight formlabel="Tax on general account equity returns (0-100%):" formname="equity_tax" type="number" overlaytext="Reduces total returns on equity portion of general account portfolio in simulation to cover dividend and capital gains taxes." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />       
            <FormTemplateLight formlabel="Tax on general account bond returns (0-100%):" formname="bond_tax" type="number" overlaytext="Reduces total returns on bond portion of general account portfolio in simulation to cover dividend and capital gains taxes." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />            
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select to apply bond tax to inflation adjustment component of return of index linked bonds</Tooltip>}>           
            <Form.Label htmlFor="apply_tax_to_inflation" className="fw-light small">Index linked bond tax treatment:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="apply_tax_to_inflation" id="apply_tax_to_inflation" className="form-control border-secondary" onChange={(e) => handleObjectChange('apply_tax_to_inflation', e.target.value)} value={presetObject.apply_tax_to_inflation}>
              <option value="0" >No tax applied to inflation adjustment</option>                
              <option value="1" >Tax applied to inflation adjustment</option>
		        </Form.Select>
            {errors.apply_tax_to_inflation && <span className="alert alert-danger">{errors.apply_tax_to_inflation}</span>}

            <FormTemplateLight formlabel="Income tax on tax deferred withdrawals (0-100%):" formname="draw_tax" type="number" overlaytext="Enter income tax rate that will be charged on withdrawals from tax deferred portion of portfolio. Gross deduction from portfolio in simulation is scaled up to cover these taxes so that net income equals income requirement." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Income tax on annuity interest rate return component (0-100%):" formname="annuity_tax_rate" type="number" overlaytext="Enter income tax rate that will be charged on annuity income for the interest rate return component of that income. Simulation also captures the income tax that will be charged on the non-interest rate return component of annuity income in proportion to the amount of the portfolio that is in tax deferred funds. Annuity purchase cost in simulation is scaled up to cover these taxes." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} /> 
            <FormTemplateLight formlabel="Income on state pension income (0-100%):" formname="annuity_tax_rate2" type="number" overlaytext="Enter income tax rate that will be deducted on state pension income." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />    
            <FormTemplateLight formlabel="Income on occupational pension income (0-100%):" formname="annuity_tax_rate3" type="number" overlaytext="Enter income tax rate that will be deducted on occupational pension income." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeTaxesModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            {/* Settings Modal */}
            <Modal show={isSettingsModalOpen} onHide={closeSettingsModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Fee and other simulation settings</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                
            <FormTemplateLight formlabel="Average portfolio fees (basis points):" formname="fees" type="number" overlaytext="Deducts annual charge from portfolio value in simulation - fees reduce returns" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Add net credit spread return to forward looking conventional bond yield (basis points):" formname="spread" type="number" overlaytext="Add net credit spread return to forward looking conventional bond yield (basis points) to simulate holding credit/corporate bonds rather than government bonds" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Add alpha spread return to all equity returns (basis points):" formname="equity_alpha" type="number" overlaytext="Add alpha spread return to equity returns" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Add alpha spread return to all bond returns (basis points):" formname="fixed_income_alpha" type="number" overlaytext="Add alpha spread return to bond returns" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            {/* <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select data-set that will be used for backtesting. Historic data sets use all historic data. Current market yield data sets use current market bond yields combined with historic equity and CPI data for scenario testing.</Tooltip>}>           
            <Form.Label htmlFor="data_option" className="fw-light small">Select backtesting data:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="data_option" id="data_option" className="form-control border-secondary" onChange={(e) => handleDataCalibrationChange('data_option', e.target.value)} value={presetObject.data_option}>
              <option value="backUSDshiller" >USD historic data for all assets (US Equities, end 1870-2023)</option>
              <option value="backUSDshort" >USD historic data for all asset (US Equities, Trinity study end 1925-1995)</option>
              <option value="backUSDglobal" >USD historic data for all assets (Global Equities, end 1899-2023)</option>   
              <option value="forwardUSD">USD current market yields for bonds, historic data equities (US Equities, end 1870-2023)</option>
              <option value="forwardUSDglobal">USD current market yields for bonds, historic data for equities (Global Equities, end 1899-2023)</option>
              <option disabled>----------</option>
              <option value="back" >GBP historic data for all assets (Global Equities, end 1899-2023)</option>
              <option value="forward">GBP current market yields for bonds, historic data for equities (Global Equities, end 1899-2023)</option>
		        </Form.Select> */}
            {/* <FormTemplateLight formlabel="Change plan's 'last update' date:" formname="plan_date" type="date" overlaytext="Change plan's 'last update' date" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} /> */}
            
            <Form.Group>
                <Form.Label htmlFor="plan_date" className="small">Change plan's 'last update' date:</Form.Label>
                <Form.Control name="plan_date" id="plan_date" className="form-control border-secondary" type="date" value={presetObject.plan_date} onChange={(e) => handleDateChange('plan_date', e.target.value)} placeholder="yyyy-mm-dd" />
                {errors.plan_date && <Alert className="alert alert-danger">{errors.plan_date}</Alert>}
            </Form.Group>


            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Linear simulation uses back-testing cycles from the first year of the historic data set up until the last completable cycle in the data set (e.g. if dataset is from 1900-2023 and the simulation is 30 years, the model will use 94 backtesting cycles with the first from 1900-1930 and the last from 1993-2023). Circular simulation starts a back-testing cycle in every year of the historic data set - if there are insufficent years to finish the cycle, it uses again the years at the beginning of the data set (e.g. if dataset is from 1900-2023 and the simulation is 30 years, the model will use 124 backtesting cycles with the first from 1900-1930, the 95th using 1994-2023 plus 1900 (to make 30 years) and the last from using 2023 plus 1900-1929 (again, to make 30 years)). </Tooltip>}>           
            <Form.Label htmlFor="circular_simulation" className="fw-light small">Simulation methodology:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="circular_simulation" id="circular_simulation" className="form-control border-secondary" onChange={(e) => handleObjectChange('circular_simulation', e.target.value)} value={presetObject.circular_simulation}>
              <option value="1" >Circular exploratory simulation</option>
              <option value="0" >Linear exploratory simulation</option>
		        </Form.Select>
            {errors.circular_simulation && <span className="alert alert-danger">{errors.circular_simulation}</span>}           
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>First year (end of) of historic dataset for backtesting </Tooltip>}>           
            <Form.Label htmlFor="data_start_year" className="fw-light small">First year (end of) of historic dataset for backtesting:</Form.Label>
            </OverlayTrigger>
            <Form.Select type="number" name="data_start_year" id="data_start_year" value={presetObject.data_start_year} onChange={(e) => handleObjectChange('data_start_year', e.target.value)} className="form-control border-secondary">
              {yearList.map((number, index) => (<option key={index} value={number}>{number}</option>))}
            </Form.Select>
            {errors.data_start_year && <span className="alert alert-danger">{errors.data_start_year}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Last year (end of) of historic dataset for backtesting </Tooltip>}>           
            <Form.Label htmlFor="data_end_year" className="fw-light small">Last year (end of) of historic dataset for backtesting:</Form.Label>
            </OverlayTrigger>
            <Form.Select type="number" name="data_end_year" id="data_end_year" value={presetObject.data_end_year} onChange={(e) => handleObjectChange('data_end_year', e.target.value)} className="form-control border-secondary">
              {yearList.map((number, index) => (<option key={index} value={number}>{number}</option>))}
            </Form.Select>
            {errors.data_end_year && <span className="alert alert-danger">{errors.data_end_year}</span>}

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeSettingsModal}>Close</Button>
              </Modal.Footer>
            </Modal>
            
            {/* Settings Modal */}
            <Modal show={isDataChoiceModalOpen} onHide={closeDataChoiceModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Back-testing data options</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                
              {/* <Form.Label htmlFor="currency_set" className="fw-light small">Select simulation currency denomination:</Form.Label>
              <Form.Select name="currency_set" id="currency_set" className="form-control border-secondary" onChange={(e) => handleTextChange('currency_set', e.target.value)} value={presetObject.currency_set}>
                <option value='GBP'>GBP</option>
                <option value='USD'>USD</option>
              </Form.Select>  */}

              <Form.Group>
              <Form.Label htmlFor="currency_set" className="fw-light small">Select simulation currency denomination:</Form.Label>
              
              <div>
                {/* USD Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="currency_set"
                  id="currency_usd"
                  label="USD - for US based investors. Equity returns are denominated in USD. Bond returns are represented by US Treasury. US inflation is used."
                  value="USD"
                  checked={presetObject.currency_set === 'USD'}
                  onChange={(e) => handleTextChange('currency_set', e.target.value)}
                />
                {/* GBP Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="currency_set"
                  id="currency_gbp"
                  label="GBP - for UK based investors. Equity returns are denominated in GBP. Bond returns are represented by UK Gilts. UK inflation is used."
                  value="GBP"
                  checked={presetObject.currency_set === 'GBP'}
                  onChange={(e) => handleTextChange('currency_set', e.target.value)}
                />
 
              </div>
              </Form.Group>

              <Form.Group>
              <Form.Label htmlFor="currency_set" className="fw-light small">Select equity return data:</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="geographic_set"
                  id="geographic_DOMESTIC"
                  label="US Equity Market - largest equity market by far, over time has delivered higher real returns than most other major equity markets but no guarantee that this will continue."
                  value="DOMESTIC"
                  checked={presetObject.geographic_set === 'DOMESTIC'}
                  onChange={(e) => handleTextChange('geographic_set', e.target.value)}
                />              
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="geographic_set"
                  id="geographic_GLOBAL"
                  label="Global (inc. US) Equity Market - incorporates a broad range of stress scenarios (global conflict, high inflation and political upheaval) on market returns."
                  value="GLOBAL"
                  checked={presetObject.geographic_set === 'GLOBAL'}
                  onChange={(e) => handleTextChange('geographic_set', e.target.value)}
                />
              </div>
              </Form.Group>


              <Form.Group>
              <Form.Label htmlFor="data_direction" className="fw-light small">Select bond return data:</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="data_direction"
                  id="data_direction_back"
                  label="Historic bond returns - suitable for investors using pooled bond funds."
                  value="back"
                  checked={presetObject.data_direction === 'back'}
                  onChange={(e) => handleTextChange('data_direction', e.target.value)}
                />              
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="data_direction"
                  id="data_direction_forward"
                  label="Forward market implied bond returns - suitable for investors who have a portfolio of bonds where each bond will be held to maturity and are cash flow matched. Gives investors a better indication of returns that they currently 'lock-in'. Gives option of allocating in simulation to either conventional bonds or index linked bonds."
                  value="forward"
                  checked={presetObject.data_direction === 'forward'}
                  onChange={(e) => handleTextChange('data_direction', e.target.value)}
                />
              </div>
              </Form.Group>

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeDataChoiceModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalmessage}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isResetModalOpen} onHide={closeResetModal }>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalResetMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeResetModal }>Cancel</Button>
              <Button variant="danger" onClick={resetPlan }>Confirm</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isRetrieveModalOpen} onHide={closeRetrieveModal} centered size="lg">
            <Modal.Header closeButton>
            <Modal.Title>User Plans</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {plans.length > 0 ? (
            <Table hover className="horizontal-border-only">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>#</th>
                  <th style={{ width: '45%' }}>Plan title</th>
                  <th style={{ width: '25%' }}>Actions</th> 
                  <th style={{ width: '25%' }}>Last update</th> 
                </tr>
              </thead>
              <tbody>
                {plans.map((plan, index) => (
                  <tr key={plan.id}>
                    <td>{index + 1}</td>
                    <td>{plan.plan_title}</td>
                    <td>
                      <Button variant="success" onClick={() => retrievePlan(plan.id)} className="me-1">Retrieve</Button>
                      <Button variant="danger" onClick={() => handleOpenDeleteModal(plan.id)}className="me-1" >Delete</Button>
                    </td>
                    <td>{plan.plan_date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            ) : (
            <p>No plans found.</p>
            )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeRetrieveModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={showDateModal} onHide={() => handleDateModalClose(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Update Portfolio Date</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Do you want to save this plan's 'Last Update' date as today's date?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {handleDateModalClose(false)}}>No, keep existing</Button>
                <Button variant="secondary" onClick={() => {handleDateModalClose(true)}}>Yes, update date</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body><p>Are you sure you want to delete this plan? This action cannot be undone.</p></Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
            <Button variant="danger" onClick={() => deletePlan(selectedPlanId)}>Confirm</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isReLoginModalOpen} onHide={closeReLoginModal}>
              <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Error connecting to server. Action could not be completed. Please try re-logging in and re-trying action.</p>
                <ReLoginForm onClose={closeReLoginModal} />
              </Modal.Body>
            </Modal>
          
          {/* this is stuffing to help the onscreen formatting */}
          {isSmallScreen ? <></> : <><br /><br /></>}

          </Col>

          <Col sm={8} className={`border border-secondary p-3 mt-2 mb-2 ${isSmallScreen ? '' : 'custom-scrollbar-col'}`} style={isSmallScreen ? {} : { overflowY: 'auto', height: '100vh' }}>
          <Row>
            <Col xs="6" sm="6">
            <div className="d-flex align-items-center">
            <p className="lead mb-0">Simulation results:</p>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">
            The simulation calculator graphics show how the strategy would have performed in each back-test cycle (each one starting from a different historic year in the dataset). The success rate measures the percentage of back-tests where the portfolio lasted until the end of the simulation. The higher this number, the higher the level of confidence in the retirement investment and expenditure strategy.
            </Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>

            <div className='my-2'></div>
            {loading4 ? <ButtonExample data={buttonlabel} /> : <button className="btn btn-success" onClick={handleSubmit} style={{ marginRight: '5px', marginBottom: '5px' }}>Run simulation</button>}
            </Col>
            <Col xs="6" sm="6">
            {/* <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Backtest failure rate gives the percentage of scenarios run in which the portfolio value fell to zero before the end of the simulation i.e. the strategy failed</Tooltip>}>            */}
            {/* <p className="lead text-end">Success rate: {100 - result.toFixed(1)}%</p> */}
            <div className="border border-light p-2 mt-2 d-inline-block float-end rounded" style={{ backgroundColor: '#343a40' }}>
            <div className={isSmallScreen ? "fs-1 bg-dark text-light" : "fs-1 bg-dark text-light"}>{100 - result.toFixed(1)}%</div>
            <div className={isSmallScreen ? "small bg-dark text-light" : "small bg-dark text-light"}>Success rate</div>
            </div> 
            {/* </OverlayTrigger> */}
            </Col>
          </Row>
          
        
          <div className="my-2" />
          
            {switchvalue === 1 ? (<MasterLineChart data={dataForChart} simulation_years={presetObject.end_simulation_age - presetObject.start_simulation_age} data_direction={presetObject.data_direction} asset_mix_equity={presetObject.asset_mix_equity} labels={unitsForYAxis} title={titleForChart} yaxistitle={yaxisTitleForChart} heightratio={0.25} percentile={percentileSettingForChart} multiplelines={multipleLinesSettingForChart} portvaluedistchart={flagPortfolioValueDistChart}/>) 
            : switchvalue === 2 ? (<MaxMinPlotChart data={decresult} heightratio={0.25}/>) 
            : switchvalue === 3 ? (switchvalue3 === 1 || switchvalue3 === 2 || switchvalue3 === 3 ? (<MasterLineChart data={dataForChart} simulation_years={presetObject.end_simulation_age - presetObject.start_simulation_age} data_direction={presetObject.data_direction} asset_mix_equity={presetObject.asset_mix_equity} labels={unitsForYAxis} title={titleForChart} yaxistitle={yaxisTitleForChart} heightratio={0.25} percentile={percentileSettingForChart} multiplelines={multipleLinesSettingForChart} portvaluedistchart={flagPortfolioValueDistChart}/>) : <TradeOffAnalysis data1={presetObject} data2={setErrors} data3={setIsModalOpen} data4={setModalmessage} heightratio={0.2}/>)
            : switchvalue === 4 ? (switchvalue2 === 1 || switchvalue2 === 2 ? (<MasterLineChart data={dataForChart} simulation_years={presetObject.end_simulation_age - presetObject.start_simulation_age} data_direction={presetObject.data_direction} asset_mix_equity={presetObject.asset_mix_equity} labels={unitsForYAxis} title={titleForChart} yaxistitle={yaxisTitleForChart} heightratio={0.25} percentile={percentileSettingForChart} multiplelines={multipleLinesSettingForChart} portvaluedistchart={flagPortfolioValueDistChart}/>) : (switchvalue2 === 3 ? (<DrawHistChart data={drawhistdata} data3={presetObject.annuity_percent_withdrawal} heightratio={0.25}/>) : (<DistTypeChart data={drawstreamsbytype} data2={presetObject.years} data3={presetObject.annuity_percent_withdrawal} data5={yearsForChartShort} heightratio={0.25}/>)))
            : (<></>)}

          <div className="my-2"></div>

            <Row>
            <Col sm="12">
            <ToggleButtonGroup type="radio" name="options" value={switchvalue} onChange={handleSwitchChange} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
                Portfolio value by year
              </ToggleButton>           
              {/* <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a2" value={2} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
                Distribution of final portfolio value
              </ToggleButton>           */}
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
                Income / withdrawal analysis
              </ToggleButton>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
                Safe funding analysis
              </ToggleButton>
            </ToggleButtonGroup>

            {switchvalue === 4 ? (
            <ToggleButtonGroup type="radio" name="options2" value={switchvalue2} onChange={handleSwitchChange2} style={{ paddingBottom: '10px' }}>
   
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b2" value={2} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Income / draw by year (by percentile)
            </ToggleButton>  
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Income / draw by year (all results)
            </ToggleButton>   
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Distribution of income / withdrawal
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Income by type by year
            </ToggleButton>
            </ToggleButtonGroup>
            ) : switchvalue === 3 ? (
            <ToggleButtonGroup type="radio" name="options3" value={switchvalue3} onChange={handleSwitchChange3} style={{ paddingBottom: '10px' }}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe funding by back-test cycle
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe funding level by age
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c2" value={2} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe withdrawal rate by age
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe funding level by asset mix
            </ToggleButton>            
            </ToggleButtonGroup>
            ) : switchvalue === 1 ? (
            <ToggleButtonGroup type="radio" name="options4" value={switchvalue4} onChange={handleSwitchChange4} style={{ paddingBottom: '10px' }}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d2" value={2} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Results by percentile
            </ToggleButton>             
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results
            </ToggleButton>           
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results (general account)
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results (tax exempt)
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d5" value={5} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results (tax deferred)
            </ToggleButton>            
            </ToggleButtonGroup>
            ) : (<></>)}
            </Col>
            </Row>
            
            {/* these are the algo to optimise asset mix buttons */}
          {/* {loading2 ? <ButtonExample data={buttonlabel} /> : <button className="btn btn-success" onClick={runMixMaxOptimisationAndReCalc} style={{ marginRight: '10px', marginBottom: '10px' }}>Auto optimise asset mix (max return)</button>}
          {loading3 ? <ButtonExample data={buttonlabel} /> : <button className="btn btn-success" onClick={runMixMinOptimisationAndReCalc} style={{ marginRight: '10px', marginBottom: '10px' }}>Auto optimise asset mix (min risk)</button>} */}

          <hr className="my-2" />           

          {/* <div className="row"> */}
            <p className='lead'>Key stats:</p>
            {/* <hr className="my-2" /> */}
            <Row>
              <div classNmae="lead">Portfolio simulation ($,£):</div>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{portfolioTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light">Portfolio starting size</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{portfoliovaluepercentiles?.[3]?.[portfoliovaluepercentiles[3]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light">Portfolio median ending size</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{portfoliovaluepercentiles?.[0]?.[portfoliovaluepercentiles[0]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light">Portfolio lowest ending size</div>
              </Col>
            </Row>
            <div className="my-2"></div>
            <hr className="my-2" />
            <Row>
              <div classNmae="lead">Safe funding levels:</div>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{safeFundingPercentiles[1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>95% success level</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{safeFundingPercentiles[2]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>99% success level</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{safeFundingPercentiles[3]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>100% success level</div>
              </Col>
            </Row>
            <div className="my-2"></div>
            <hr className="my-2" />

            <Row>
              <div className="lead">Safe withdrawal rates:</div>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeWithdrawalPercentiles?.[1]?.toFixed(1) || '0'}%
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>95% success level</div>
              </Col>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeWithdrawalPercentiles?.[2]?.toFixed(1) || '0'}%
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>99% success level</div>
              </Col>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeWithdrawalPercentiles?.[3]?.toFixed(1) || '0'}%
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>100% success level</div>
              </Col>
            </Row>

            <div className="my-2"></div>
            <hr className="my-2" />
            <Row>
              <div classNmae="lead">Income / withdrawal development ($,£ net of tax):</div>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{incomepercentiles?.[3]?.[3]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Initial income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{incomepercentiles?.[3]?.[incomepercentiles[3]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Median last income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{Array.isArray(incomepercentiles) && incomepercentiles[0] ? Math.min(...incomepercentiles[0]).toLocaleString(undefined, { maximumFractionDigits: 0 }) : '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Lowest income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{averagedraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Average income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{averageadjusteddraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Average mortality adjusted income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              </Col>
            </Row>
            <div className="col-sm-12">


          {/* <Table hover>
            <tbody>
              <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Asset mix:</td>
                <td className="bg-dark text-light" style={{ border: 'none' }}>{presetObject.data_direction === 'forward' ? <div>Equity = {presetObject.asset_mix_equity.toFixed(1)}%; Bonds (conventional) = {presetObject.asset_mix_bond.toFixed(1)}%; Bonds (index) = {presetObject.asset_mix_index_bond.toFixed(1)}%</div> : <div>Equity = {presetObject.asset_mix_equity.toFixed(1)}%, bonds (conventional) = {presetObject.asset_mix_bond.toFixed(1)}%</div> }</td>
              </tr>        
              {switchvalue !== 4 && (
              <>
                {portfolioTotal === null || portfolioTotal === undefined ? 
                (
                <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Portfolio starting size ($,£): </td>
                <td>{portfolioTotal}</td>
                </tr>
                )
                :
                (
                <>
                <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Portfolio starting size ($,£):  </td>
                <td className="bg-dark text-light" style={{ border: 'none' }}>{portfolioTotal.toLocaleString()}</td>
                </tr>
                <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Portfolio median ending size ($,£): </td>
                <td className="bg-dark text-light" style={{ border: 'none' }}>{portfoliovaluepercentiles?.[3]?.[portfoliovaluepercentiles[3]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 'Data not available'}</td>
                </tr>
                <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Portfolio lowest ending size ($,£): </td>
                <td className="bg-dark text-light" style={{ border: 'none' }}>{portfoliovaluepercentiles?.[0]?.[portfoliovaluepercentiles[0]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 'Data not available'}</td>
                </tr>
                </>
                )
                }
              </>
            )}
              {safeFundingPercentiles.length === 4 ? (
                <>
                <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Safe Funding Levels: </td>
                <td className="bg-dark text-light" style={{ border: 'none' }}>95% Success Rate = {safeFundingPercentiles[1].toLocaleString()}; 99% = {safeFundingPercentiles[2].toLocaleString()}; 100% = {safeFundingPercentiles[3].toLocaleString()}</td>
                </tr>
                <tr>
                <td className="bg-dark text-light" style={{ border: 'none' }}>Safe Withdrawal Rates: </td>
                <td className="bg-dark text-light" style={{ border: 'none' }}>95% Success Rate = {safeWithdrawalPercentiles[1].toFixed(1)}%; 99% = {safeWithdrawalPercentiles[2].toFixed(1)}%; 100% = {safeWithdrawalPercentiles[3].toFixed(1)}%</td>
                </tr>
                </>
              ) : (
                <>
                </>
              )}

              {switchvalue === 4 ? (
              <>
              <tr>
              <td className="bg-dark text-light" style={{ border: 'none' }}>Initial income / withdrawal value ($,£ net of tax): </td>
              <td className="bg-dark text-light" style={{ border: 'none' }}>{incomepercentiles?.[3]?.[3]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 'Data not available'}</td>
              </tr>
              <tr>
              <td className="bg-dark text-light" style={{ border: 'none' }}>Median last income / withdrawal value ($,£ net of tax): </td>
              <td className="bg-dark text-light" style={{ border: 'none' }}>{incomepercentiles?.[3]?.[incomepercentiles[3]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 'Data not available'}</td>
              </tr>
              <tr>
              <td className="bg-dark text-light" style={{ border: 'none' }}>Lowest income / withdrawal value ($,£ net of tax):</td>
              <td className="bg-dark text-light" style={{ border: 'none' }}>{Array.isArray(incomepercentiles) && incomepercentiles[0] ? Math.min(...incomepercentiles[0]).toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'Data not available'}</td>
              </tr>
              <tr>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Average annual income (current value) over all simulation cycles</Tooltip>}>           
              <td className="bg-dark text-light" style={{ border: 'none' }}>Average income / withdrawal value ($,£ net of tax):</td> 
              </OverlayTrigger>
              <td className="bg-dark text-light" style={{ border: 'none' }}>{averagedraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
              </tr>
              <tr>
              <td className="bg-dark text-light" style={{ border: 'none' }}>Average mortality adjusted income / withdrawal value ($,£ net of tax):
              <OverlayTrigger
              placement="right"
              overlay={
              <Tooltip id="tooltip-right">
              Average annual income (current value) over all simulation cycles with each withdrawal multiplied by percentage probability of person living to the age at time received in simulation (using US life expectancy data, average male/female). This metric favours withdrawal strategies that pay-out more earlier on in the simulation.
              </Tooltip>}>
              <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
              <FaInfoCircle style={{ color: '#adb5bd', fontSize: '0.8rem' }} />
              </span>
              </OverlayTrigger>
              </td>
              <td className="bg-dark text-light" style={{ border: 'none' }}>{averageadjusteddraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </td>
              </tr>
              </>       
            ): (<></>)}

            </tbody>
          </Table> */}


            {/* <div className="d-flex align-items-center">
            <div className={isSmallScreen ? 'small' : 'small'}>Average withdrawal value ($,£ net of tax): {averagedraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>     
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">
            Average withdrawal ($,£ current value) over all simulation cycles.
            </Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '0.8rem' }} />
            </span>
            </OverlayTrigger>
            </div>

            <div className="d-flex align-items-center">
            <div className={isSmallScreen ? 'small' : 'small'}>Average mortality adjusted withdrawal value ($,£ net of tax): {averageadjusteddraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">
            Average annual income (current value) over all simulation cycles with each withdrawal multiplied by percentage probability of person living to the age at time received in simulation (data: US life expectancy, average male/female).
            </Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '0.8rem' }} />
            </span>
            </OverlayTrigger>
            </div>            

            <div className="d-flex align-items-center">
            <div className={isSmallScreen ? 'small' : 'small'}>Average discounted sum of mortality adjusted income values ($,£ net of tax): {sumdrawadjustedavg.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">
            Average discounted sum of annual income (current value) over simulation cycle with each withdrawal multiplied by percentage probability of person living to the age at time received in simulation (data: US life expectancy, average male/female). Discount adjustment uses current market real premium / discount (after inflation) taken from government inflation linked bond curve).
            </Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '0.8rem' }} />
            </span>
            </OverlayTrigger>
            </div>     */}


   

            {/* <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Average discounted sum of annual income (current value) over simulation cycle with each withdrawal multiplied by percentage probability of person living to the age at time received in simulation (data: US life expectancy, average male/female). Discount adjustment uses current market real premium / discount (after inflation) taken from government inflation linked bond curve).</Tooltip>}>           
            <div className={isSmallScreen ? 'small' : 'small'}>Average discounted sum of mortality adjusted income values ($,£ net of tax): {sumdrawadjustedavg.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
            </OverlayTrigger>      */}

            {/* this is stuffing to help the onscreen formatting */}
            {isSmallScreen ? <></> : <><br /><br /><br /></>}
            </div>
            {/* <div className="col-sm-0">
            </div> */}
          {/* </div> */}

          </Col>
          </Row>
      </Container>
      </div>
    )
};

export default CalcDash1

