import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'; // Import Modal from React-Bootstrap
import useLogin from '../utils/login';
import { useAuthStore } from '../store/auth';

const LoginForm = ({ onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
    const [errorModal, setErrorModal] = useState(false); // State to control error modal visibility
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn()) {
            onClose(); // Close the modal if already logged in
        }
    }, [isLoggedIn, onClose]);

    const resetForm = () => {
        setUsername('');
        setPassword('');
        setErrorMessage(''); // Reset the error message
    };

    const { login } = useLogin();

    const handleLogin = async (e) => {
        e.preventDefault();
        const { error } = await login(username, password);

        if (error) {
            // If error is related to username or password, set the error message
            if (error.username) {
                setErrorMessage(error.username);
            } else if (error.password) {
                setErrorMessage(error.password);
            } else {
                // If it's an unhandled error, reset the form and show the error modal
                resetForm();
                setErrorModal(true);
            }
        } else {
            // On successful login, reset form and close modal
            resetForm();
            onClose();
        }
    };

    // Close the error modal
    const handleCloseErrorModal = () => {
        setErrorModal(false);
    };

    return (
        <>
            <section>
                <Form onSubmit={handleLogin}>
                    <Form.Group>
                        <Form.Label htmlFor="userName" className="small">Username:</Form.Label>
                        <Form.Control 
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="password" className="small">Password:</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}                
                        />
                    </Form.Group>
                    {/* Display error message */}
                    {errorMessage && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessage}
                        </div>
                    )}
                    <br />
                    <Button variant="success" type="submit">
                        Log In
                    </Button>
                </Form>
            </section>

            {/* Error modal to show in case of unhandled error */}
            <Modal show={errorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Something went wrong. Contact administrator if issue persists.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LoginForm;

// import React, { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import useLogin from '../utils/login';
// import { useAuthStore } from '../store/auth';

// const LoginForm = ({ onClose }) => {
//     // const navigate = useNavigate();
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
//     const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

//     useEffect(() => {
//         if (isLoggedIn()) {
//             onClose(); // Close the modal if already logged in
//         }
//     }, [isLoggedIn, onClose]);

//     const resetForm = () => {
//         setUsername('');
//         setPassword('');
//         setErrorMessage(''); // Reset the error message
//     };

//     const { login } = useLogin();

//     const handleLogin = async (e) => {
//         e.preventDefault();
//         const { error } = await login(username, password);
//         if (error) {
//             setErrorMessage(error); // Set the error message to be displayed
//         } else {
//             resetForm();
//             onClose(); // Close the modal on successful login
//         }
//     };

//     return (
//         <section>
//             <Form onSubmit={handleLogin}>
//                 <Form.Group>
//                     <Form.Label htmlFor="userName" className="small">Username:</Form.Label>
//                     <Form.Control 
//                         type="text"
//                         id="username"
//                         name="username"
//                         value={username}
//                         onChange={(e) => setUsername(e.target.value)}
//                     />
//                 </Form.Group>
//                 <Form.Group>
//                     <Form.Label htmlFor="password" className="small">Password:</Form.Label>
//                     <Form.Control
//                         type="password"
//                         id="password"
//                         name="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}                
//                     />
//                 </Form.Group>
//                 {/* Display error message */}
//                 {errorMessage && (
//                     <div style={{ color: 'red', marginTop: '10px' }}>
//                         {errorMessage}
//                     </div>
//                 )}
//                 <br />
//                 <Button variant="success" type="submit">
//                     Log In
//                 </Button>
//             </Form>
//         </section>
//     );
// };

// export default LoginForm;


