import React from 'react'

function ValidateFlexIncomeForm (presetObject, flexIncomeName, flexIncomeYear, flexIncomeYearEnd, flexIncomeAmount, flexIncomeIncrease, flexIncomeDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (flexIncomeName.length > 255) {newErrors.flexIncomeName = 'Name description must be less than 255 characters'}
    if (flexIncomeYear < presetObject.start_simulation_age || flexIncomeYear > presetObject.end_simulation_age) {newErrors.flexIncomeYear = 'Start age must be inside range of simulation start age and end age'}
    if (flexIncomeYearEnd < presetObject.start_simulation_age || flexIncomeYearEnd > presetObject.end_simulation_age) {newErrors.flexIncomeYearEnd = 'End age must be inside range of simulation start age and end age'}
    if (flexIncomeAmount < 0) {newErrors.flexIncomeAmount = 'Can not be negative'}
    if (isNaN(flexIncomeAmount) || flexIncomeAmount === null || flexIncomeAmount === undefined || String(flexIncomeAmount).trim() === '') {newErrors.flexIncomeAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(flexIncomeIncrease) || flexIncomeIncrease === null || flexIncomeIncrease === undefined || String(flexIncomeIncrease).trim() === '') {newErrors.flexIncomeIncrease = 'Can not have empty fields. Enter a value.'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateFlexIncomeForm
