import React from 'react'

function ValidateIncomeForm (presetObject, incomeName, incomeYear, incomeYearEnd, incomeAmount, incomeIncrease, incomeIncrease2, incomeDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (incomeName.length > 255) {newErrors.incomeName = 'Name description must be less than 255 characters'}
    if (incomeYear < presetObject.start_simulation_age || incomeYear > presetObject.end_simulation_age) {newErrors.incomeYear = 'Start age must be inside range of simulation start age and end age'}
    if (incomeYearEnd < presetObject.start_simulation_age || incomeYearEnd > presetObject.end_simulation_age) {newErrors.incomeYearEnd = 'End age must be inside range of simulation start age and end age'}
    if (incomeAmount < 0) {newErrors.incomeAmount = 'Can not be negative'}
    if (isNaN(incomeAmount) || incomeAmount === null || incomeAmount === undefined || String(incomeAmount).trim() === '') {newErrors.incomeAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(incomeIncrease) || incomeIncrease === null || incomeIncrease === undefined || String(incomeIncrease).trim() === '') {newErrors.incomeIncrease = 'Can not have empty fields. Enter a value.'}
    if (isNaN(incomeIncrease2) || incomeIncrease2 === null || incomeIncrease2 === undefined || String(incomeIncrease2).trim() === '') {newErrors.incomeIncrease2 = 'Can not have empty fields. Enter a value.'}
    
    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateIncomeForm
