import React, { useEffect, useState, Component } from 'react'
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; 

function DrawHistChart(props) {

  // function isMobileDevice() {
  //   return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  // };

  // function setAspectRatio() {
  //   var aspectRatio = isMobileDevice() ? false : true; // Adjust the values as needed text
  //   return aspectRatio;
  // };

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  const [title, setTitle] = useState();

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * props.heightratio; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  useEffect(() => {
    props.data3 === 0 ? setTitle('Distribution of annual income/withdrawal (current $,\u00A3, across all back-test simulation cycles, net of tax)') : setTitle('Distribution of annual income/withdrawal (current $,\u00A3, across all back-test simulation cycles, net of tax, including purchased annuity income)');
  }, [props.data3]);

  const labelrange = props.data.bins.map((bin, index) => `${bin.toFixed(0)} - ${((props.data.bins[index + 1] || bin) - 1).toFixed(0)}`)

  const chartData = {
    labels: labelrange.slice(0, -1),

    datasets: [
      {
        label: 'Frequency',
        data: props.data.histogram,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
  
  const chartOptions = {
    responsive: true,
    // maintainAspectRatio: setAspectRatio(),
    // maintainAspectRatio: isLargeScreen,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: 'Income/withdrawal value',
          color: 'white',
        },
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
          callback: (value) => `${(value * 100).toFixed(0)}%`,
        },
        title: {
          display: true,
          text: 'Frequency %',
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        display: false, 
      },
      title: {
        display: true,
        color: 'white',

        text: title, 
        font: {
          size: 14, 
        },
      },
    },
  };
  
  return (
    // <div style = {{ width:'100%', height:'300px'}}>
    //   <Bar data={chartData} options={chartOptions} />
    // </div>

  <div>
  {isSmallScreen ? (
    <div style={{ width: '100%', height: '300px' }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  ) : (
    <div style={{ width: '100%', height: `${chartHeight}px` }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
    
  )}
  <br></br>
  </div>

  );
};


export default DrawHistChart;

