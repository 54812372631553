import React from 'react'

function ValidateOccupationalPensionForm (presetObject, occupationalName, occupationalYear, occupationalAmount, occupationalIncrease, occupationalDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (occupationalName.length > 255) {newErrors.occupationalName = 'Name description must be less than 255 characters'}

    if (occupationalAmount < 0) {newErrors.occupationalAmount = 'Can not be negative'}
    if (isNaN(occupationalAmount) || occupationalAmount === null || occupationalAmount === undefined || String(occupationalAmount).trim() === '') {newErrors.occupationalAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(occupationalIncrease) || occupationalIncrease === null || occupationalIncrease === undefined || String(occupationalIncrease).trim() === '') {newErrors.occupationalIncrease = 'Can not have empty fields. Enter a value.'}
    if (occupationalYear < presetObject.start_simulation_age || occupationalYear > presetObject.end_simulation_age) {newErrors.occupationalYear = 'Start age must be inside range of simulation start age and end age'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateOccupationalPensionForm
