import React from 'react'

function ValidatePortfolioForm (portfolioName, portfolioAmount, portfolioEquity, portfolioBonds, portfolioIndex, portfolioDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (portfolioName.length > 255) {newErrors.portfolioName = 'Name description must be less than 255 characters'}

    if (portfolioAmount < 0) {newErrors.portfolioAmount = 'Can not be negative'}
    if (isNaN(portfolioAmount) || portfolioAmount === null || portfolioAmount === undefined || String(portfolioAmount).trim() === '') {newErrors.portfolioAmount = 'Can not have empty fields. Enter a value.'}
    if (portfolioEquity < 0) {newErrors.portfolioEquity = 'Can not be negative'}
    if (portfolioEquity > 100) {newErrors.portfolioEquity = 'Can not be > 100'}
    if (isNaN(portfolioEquity) || portfolioEquity === null || portfolioEquity === undefined || String(portfolioEquity).trim() === '') {newErrors.portfolioEquity = 'Can not have empty fields. Enter a value.'}
    if (portfolioBonds < 0) {newErrors.portfolioBonds = 'Can not be negative'}
    if (portfolioBonds > 100) {newErrors.portfolioBonds = 'Can not be > 100'}
    if (isNaN(portfolioBonds) || portfolioBonds === null || portfolioBonds === undefined || String(portfolioBonds).trim() === '') {newErrors.portfolioBonds = 'Can not have empty fields. Enter a value.'}
    if (portfolioIndex < 0) {newErrors.portfolioIndex = 'Can not be negative'}
    if (portfolioIndex > 100) {newErrors.portfolioIndex = 'Can not be > 100'}
    if (isNaN(portfolioIndex) || portfolioIndex === null || portfolioIndex === undefined || String(portfolioIndex).trim() === '') {newErrors.portfolioIndex = 'Can not have empty fields. Enter a value.'}
    if ((parseFloat(portfolioEquity) + parseFloat(portfolioBonds) + parseFloat(portfolioIndex)) !== 100) {setModalmessage('Sum of asset mix percentages must equal 100'); setIsModalOpen(true); return false}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidatePortfolioForm
