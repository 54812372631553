import React, { useState, useEffect } from 'react';
import ButtonExample from "./loadingbutton.js";
import ValidateForm from "./validation.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SafeFundingByAssetMixChart from "../components/safefundingbyassetmix";

function TradeOffAnalysis(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300);
  const [presetObjectCopy, setPresetObjectCopy] = useState({});
  const [maxSWRResults, setMaxSWRResults] = useState(Array(11).fill(null));
  const [adjustedSumIncomeResults, setAdjustedSumIncomeResults] = useState(Array(11).fill(null));
  const [endValueResults, setEndValueResults] = useState(Array(11).fill(null));
  const [loading, setLoading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Calculating...');
  const [conventionalOrIndex, setConventionalOrIndex] = useState('0');
  const [conventionalOrIndexInteger, setConventionalOrIndexInteger] = useState(0);

  useEffect(() => {
    const updateChartHeight = () => {
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * props.heightratio;
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => window.removeEventListener('resize', updateChartHeight);
  }, []);

  useEffect(() => {
    setPresetObjectCopy(props.data1);
  }, [props.data1]);
  
  useEffect(() => {
    if (presetObjectCopy.asset_mix_source !== '1') {
      setPresetObjectCopy(prev => ({ ...prev, asset_mix_source: '1' }));
    }
  }, [presetObjectCopy]);
  
  useEffect(() => {
    if (presetObjectCopy.data_direction === 'back') {
      setConventionalOrIndex('0');
    }
  }, [presetObjectCopy.data_direction]);

  useEffect(() => {
    conventionalOrIndex === '0' ? setConventionalOrIndexInteger(0) : setConventionalOrIndexInteger(1)
  }, [conventionalOrIndex])

  const getTradeOffAnalysis = async (i) => {
    if (ValidateForm(presetObjectCopy, props.data2, props.data3, props.data4)) {
    try {
      const apiroute = 'simulation';
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: { 'HTTP_API_KEY': 'testkeytestkey99' },
        body: JSON.stringify({
          ...presetObjectCopy,
          asset_mix_equity: i * 10,
          asset_mix_bond: (100 - i * 10) * (1 - conventionalOrIndexInteger),
          asset_mix_index_bond: (100 - i * 10) * (conventionalOrIndexInteger),
        }),
      });
      const data = await response.json();
      return {
        maxSWR: data.max_SWR_by_simulation_year[0],
        adjustedSumIncome: data.sum_mort_adjusted_discounted_income,
        endValue: data.portfolio_end_value_decile[3],
      };
    } catch (error) {
      console.log(error);
      return {
        maxSWR: null,
        adjustedSumIncome: null,
        endValue: null,
      };
    }
  }};

  const makeMultiplePosts = async () => {
    setLoading(true);
    const results = await Promise.all(
      Array.from({ length: 11 }, (_, i) => getTradeOffAnalysis(i))
    );
    const maxSWRResults = results.map(result => result.maxSWR);
    const adjustedSumIncomeResults = results.map(result => result.adjustedSumIncome);
    const endValueResults = results.map(result => result.endValue);
    setMaxSWRResults(maxSWRResults);
    setAdjustedSumIncomeResults(adjustedSumIncomeResults);
    setEndValueResults(endValueResults);
    setLoading(false);
  };

  return (
    <div>
          <hr className="my-2" />          
          {/* <h6>Safe initial funding level by asset mix analysis (analysis requires seperate initiation from rest of simulation calculator)</h6> */}

          {presetObjectCopy.data_direction === 'forward' ? (

          <Row>   
          <Form.Group as={Col}>
          <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select asset mix. </Tooltip>}>           
          <Form.Label htmlFor="conventionalOrIndex" className="fw-light">Select asset mix:</Form.Label>
          </OverlayTrigger>
          <Form.Select
            name="conventionalOrIndex"
            id="conventionalOrIndex"
            className="form-control bg-secondary border-secondary text-light"
            style={{ marginRight: '5px', marginBottom: '5px' }}
            onChange={(e) => setConventionalOrIndex(e.target.value)}
            value={conventionalOrIndex}>          
            <option value='0' >Equity vs. conventional bonds</option>
            <option value='1' >Equity vs. index linked bonds</option>
          </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="d-flex align-items-end">
          {loading ? (
            <ButtonExample data={buttonLabel} />
          ) : (
            <button className="btn btn-success" onClick={makeMultiplePosts} style={{ marginRight: '5px', marginBottom: '5px' }}>
              Initiate analysis
            </button>
          )}
          </Form.Group>
          </Row>
        ) : (
          <>
          {loading ? (
            <ButtonExample data={buttonLabel} />
          ) : (
            <button className="btn btn-success" onClick={makeMultiplePosts} style={{ marginRight: '5px', marginBottom: '5px' }}>
              Initiate analysis
            </button>
          )}
          </>
        )
      }
      <div className = "my-2"></div>
      <SafeFundingByAssetMixChart data={maxSWRResults} data2={conventionalOrIndex} heightratio={props.heightratio}/>
    </div>
  );
}

export default TradeOffAnalysis;
